export const FETCH_SIGNIN_INITIATED = 'FETCH_SIGNIN_INITIATED';
export const FETCH_SIGNIN_SUCCEEDED = 'FETCH_SIGNIN_SUCCEEDED';
export const FETCH_SIGNIN_FAILED = 'FETCH_SIGNIN_FAILED';

export const FETCH_SIGNOUT_INITIATED = 'FETCH_SIGNOUT_INITIATED';
export const FETCH_SIGNOUT_SUCCEEDED = 'FETCH_SIGNOUT_SUCCEEDED';
export const FETCH_SIGNOUT_FAILED = 'FETCH_SIGNOUT_FAILED';

export const REQUEST_OTP_INITIATED = 'REQUEST_OTP_INITIATED';
export const REQUEST_OTP_SUCCEEDED = 'REQUEST_OTP_SUCCEEDED';
export const REQUEST_OTP_FAILED = 'REQUEST_OTP_FAILED';

export const CONFIRM_OTP_INITIATED = 'CONFIRM_OTP_INITIATED';
export const CONFIRM_OTP_SUCCEEDED = 'CONFIRM_OTP_SUCCEEDED';
export const CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED';
export const CLEAR_OTP = 'CLEAR_OTP';

export const FETCH_PASSWORD_RESET_INITIATED = 'FETCH_PASSWORD_RESET_INITIATED';
export const FETCH_PASSWORD_RESET_SUCCEEDED = 'FETCH_PASSWORD_RESET_SUCCEEDED';
export const FETCH_PASSWORD_RESET_FAILED = 'FETCH_PASSWORD_RESET_FAILED';

export const FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED =
  'FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED';
export const FETCH_PASSWORD_RESET_CONFIRMATION_SUCCEEDED =
  'FETCH_PASSWORD_RESET_CONFIRMATION_SUCCEEDED';
export const FETCH_PASSWORD_RESET_CONFIRMATION_FAILED = 'FETCH_PASSWORD_RESET_CONFIRMATION_FAILED';
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';

export const FETCH_CREATE_ACCOUNT_INITIATED = 'FETCH_CREATE_ACCOUNT_INITIATED';
export const FETCH_CREATE_ACCOUNT_SUCCEEDED = 'FETCH_CREATE_ACCOUNT_SUCCEEDED';
export const FETCH_CREATE_ACCOUNT_FAILED = 'FETCH_CREATE_ACCOUNT_FAILED';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ACCOUNT_CREATION = 'CLEAR_ACCOUNT_CREATION';
export const ACCEPT_TERMS_CONDITIONS = 'ACCEPT_TERMS_CONDITIONS';
