import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import {
  IFetchRatesFailedAction,
  IFetchRatesInitiatedAction,
  IFetchRatesSucceededAction,
  IRate,
} from './types';

export const fetchRatesInitiated: ActionCreator<IFetchRatesInitiatedAction> = () => ({
  type: TYPES.FETCH_RATES_INITIATED,
  payload: null,
});

export const fetchRatesSucceeded: ActionCreator<IFetchRatesSucceededAction> = (rates: IRate[]) => ({
  type: TYPES.FETCH_RATES_SUCCEEDED,
  payload: rates,
});

export const fetchRatesFailed: ActionCreator<IFetchRatesFailedAction> = () => ({
  type: TYPES.FETCH_RATES_FAILED,
  payload: null,
});
