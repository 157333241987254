import FormBackArrow from 'src/components/FormBackArrow';
import CustomBtn from 'src/components/Button';
import styles from './index.module.css';
import { IProps } from 'src/modules/theia/util/types';

const TermsAndConditions = (props: IProps) => {
  return (
    <div className={styles.container}>
      <FormBackArrow onClick={props.onBack} classname={styles.back} iconSize={14} />
      <p className={styles.accept}>
        Accept Terms and
        <br />
        Conditions
      </p>
      <CustomBtn htmlType="submit" onClick={props.onNext}>
        Accept and open account
      </CustomBtn>
    </div>
  );
};
export default TermsAndConditions;
