import request from '../../../utils/request';

export const getBalances = async () => {
  const response = await request.get('/user/query/balance');

  if (response.status === 200 && response.data.success) {
    return response.data.data;
  }
  return [];
};
