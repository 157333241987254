import { Typography, Modal } from 'antd';
import format from 'date-fns/format';
import CancelIcon from 'src/components/CancelIcon';
import { DefaultFormInput } from 'src/components/CustomInputs';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import BackArrow from 'src/modules/theia/auth/component/BackArrow';
import { camelCaseToCapitalizedWord } from 'src/utils/common';
import './TransactionDetailCard.css';

interface ITransactionDetailCardProps {
  transactionId?: string;
  data?: {
    label: string;
    value: string;
  }[];
  close?(): void;
}
const DemoValue = {
  transactionId: '...',
  data: [],
};

const { Text, Title } = Typography;

const TransactionDetailCard = ({ transactionId, data, close }: ITransactionDetailCardProps) => {
  return (
    <div className="tdc">
      <div className="header">
        <BackArrow onClick={close} classname="back-icon" />
        <Title level={2}>Transaction {transactionId}</Title>
        <CancelIcon onClick={close} classname="cancel-icon" />
      </div>
      <div className="container">
        {data && data.length > 1 && (
          <>
            <ul>
              {data.map((row, idx) => (
                <li key={row.label + idx}>
                  <span className="label">{camelCaseToCapitalizedWord(row.label)}</span>
                  <Text strong className="value" ellipsis>
                    {/^Date$/i.test(row.label) && !isNaN(Date.parse(row.value))
                      ? format(new Date(row.value), 'MMMM, d')
                      : row.value}
                  </Text>
                </li>
              ))}
            </ul>
            <DefaultFormInput
              label="Add a note:"
              name="note"
              labelClassName="label"
              type="text"
              placeholder="Optional"
            />
          </>
        )}
      </div>
    </div>
  );
};

const TransactionDetailCardContainer = ({
  transactionId,
  data,
  close,
}: ITransactionDetailCardProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      {isMobile ? (
        <Modal
          visible={!!transactionId}
          closable={false}
          width="100%"
          className="detail"
          footer={null}
          style={{ top: 0, height: '100vh', maxWidth: '100vw', margin: 0 }}
        >
          <TransactionDetailCard {...{ transactionId, data, close }} />
        </Modal>
      ) : (
        <aside>
          <TransactionDetailCard {...{ transactionId, data, close }} />
        </aside>
      )}
    </>
  );
};

export default TransactionDetailCardContainer;

TransactionDetailCardContainer.defaultProps = DemoValue;
