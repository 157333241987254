import { useRef, useState, useCallback } from 'react';
import { Image, Form, Button, Carousel } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { IUserSignIn } from 'src/common/interface';
import AuthModal from '../AuthModal';
import { DefaultFormInput } from 'src/components/CustomInputs';
import CustomBtn from 'src/components/Button';
import AuthForm from '../AuthFormWrapper';

import caroucelImage from 'src/assets/images/caroucel.png';

import 'antd/dist/antd.css';
import styles from './login.module.css';

interface IFormError {
  username?: string[];
  password?: string[];
}
type TFormError = IFormError | null;
interface LoginModalProps {
  isLoading?: boolean;
  isVisible: boolean;
  onLogin: (data?: IUserSignIn) => void;
  onPasswordReset?: Function;
  onSignup?: Function;
}
const defaultProps = {
  isVisible: false,
  isLoading: false,
  onLogin: () => {},
  onPasswordReset: () => {},
  onSignup: () => {},
};

const FORMITEMS = {
  username: 'username',
  password: 'password',
};

const LoginModal = ({
  isVisible,
  onLogin,
  onPasswordReset,
  onSignup,
  isLoading,
}: LoginModalProps & typeof defaultProps) => {
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();
  const slider: any = useRef();

  const handleFormChange = useCallback(() => {
    const usernameError = form.getFieldError(FORMITEMS.username);
    const passwordError = form.getFieldError(FORMITEMS.password);

    const errors = [...usernameError, ...passwordError].length
      ? {
          ...(usernameError.length ? { username: usernameError } : {}),
          ...(passwordError.length ? { password: passwordError } : {}),
        }
      : null;

    setFormErrors(errors);
  }, [form]);

  const caruuselImages = [
    {
      preview: false,
      src: caroucelImage,
    },
    { preview: false, src: caroucelImage },
  ];

  const footerLinks = [
    {
      onClick: onPasswordReset,
      disabled: isLoading,
      text: 'Forgot your password?',
      icon: <RightOutlined className={styles.rightArrow} />,
    },
    {
      onClick: onSignup,
      disabled: isLoading,
      text: "Don't have an account? Get Started in just 5 minutes.",
      icon: <RightOutlined className={styles.rightArrow} />,
    },
  ];

  return (
    <div className="loginModal">
      <AuthModal isVisible={isVisible}>
        <div className={`${styles.loginModal__headerContainer}`}>
          <div className={styles.loginModal__carouselContainer}>
            <Carousel
              dots={false}
              ref={(ref: any) => {
                slider.current = ref;
              }}
            >
              {caruuselImages.map((item, index) => (
                <div key={index} className={styles.loginModal__carouselImageDiv}>
                  <div className={styles.loginModal__carouselImageDiv2}>
                    <div className={styles.loginModal__carouselImage}>
                      <Image {...item} />
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.loginModal__headerArrowContainer}>
            <Button
              onClick={() => {
                slider.current.prev();
              }}
              type="ghost"
              className={styles.carouselArrow}
              icon={<LeftOutlined />}
            />
            <Button
              onClick={() => {
                slider.current.next();
              }}
              type="ghost"
              className={styles.carouselArrow}
              icon={<RightOutlined />}
            />
          </div>
          <div className={`${styles.loginModal__HeaderContainer}`}>
            <p className={styles.loginModal__headertext}>ReadyFx</p>
          </div>
        </div>
        <AuthForm
          formBodyClass={styles.loginModal__content}
          formDescription="Please enter your username and password"
        >
          <Form
            form={form}
            onFieldsChange={handleFormChange}
            name="login_form"
            layout={'vertical'}
            onFinish={onLogin}
          >
            <div>
              <div>
                <Form.Item
                  name={FORMITEMS.username}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <DefaultFormInput
                    error={formError ? !!formError.username : false}
                    label="Username"
                    type="email"
                    placeholder="username@email.com"
                  />
                </Form.Item>
                <Form.Item
                  name={FORMITEMS.password}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <DefaultFormInput
                    error={formError ? !!formError.password : false}
                    label={'Password'}
                    type="password"
                    placeholder="***********"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.loginModal__btnContainer}>
              <Form.Item className={styles.loginModal__submitBtn}>
                <CustomBtn
                  disabled={isLoading || !!formError}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className={styles.loginBtn}
                >
                  Login
                </CustomBtn>
              </Form.Item>
            </div>
          </Form>
        </AuthForm>
        <div className={styles.loginModal__linkContainer}>
          {footerLinks.map(({ icon, text, ...rest }, index) => (
            <Button className={styles.footerLink} key={index} {...rest}>
              {text}
              {icon}
            </Button>
          ))}
        </div>
      </AuthModal>
    </div>
  );
};

LoginModal.defaultProps = defaultProps;

export default LoginModal;
