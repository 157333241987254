import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationState } from '../../../common/interface';
import { fetchBalancesSucceeded, fetchBalancesFailed } from './actions';
import {
  FETCH_BALANCES_INITIATED,
  FETCH_BALANCES_SUCCEEDED,
  FETCH_BALANCES_FAILED,
} from './actionTypes';
import { getBalances } from './requests';
import { IBalanceState, IFetchBalancesInitiatedAction, IBalance, IBalanceActions } from './types';

export const INITIAL_STATE: IBalanceState = {
  loading: false,
  balances: [],
};

export function* getBalancesRequest(action: IFetchBalancesInitiatedAction) {
  try {
    const balances: IBalance[] = yield call(getBalances);

    yield put(fetchBalancesSucceeded(balances));
  } catch (e) {
    yield put(fetchBalancesFailed());
  }
}

export function* GetBalances() {
  yield takeLatest(FETCH_BALANCES_INITIATED, getBalancesRequest);
}

export const selectBalance = (state: IApplicationState) => state.balance;

export default function reducer(state = INITIAL_STATE, action: IBalanceActions) {
  switch (action.type) {
    case FETCH_BALANCES_INITIATED:
      return { ...state, loading: true };
    case FETCH_BALANCES_SUCCEEDED:
      return { ...state, loading: false, balances: action.payload as IBalance[] };
    case FETCH_BALANCES_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
