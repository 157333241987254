import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationState } from 'src/common/interface';
import API from './requests';
import {
  fetchPayeeSucceded,
  fetchPayeeFailed,
  deletePayeeSucceded,
  deletePayeeFailed,
  addPayeeFailed,
  addPayeeSuccessful,
} from './actions';
import {
  FETCH_PAYEE_INITIATED,
  FETCH_PAYEE_SUCCEEDED,
  FETCH_PAYEE_FAILED,
  DELETE_PAYEE_INITIATED,
  DELETE_PAYEE_SUCCEEDED,
  DELETE_PAYEE_FAILED,
  ADD_PAYEE_FAILED,
  ADD_PAYEE_INITIATED,
  ADD_PAYEE_SUCCESSFUL,
  CLEAR_PAYEE_STATE,
} from './actionTypes';
import {
  IAddPayeeInitiatedAction,
  IPayeeState,
  IPayee,
  IDeletePayeeInitiatedAction,
  IFetchPayeeInitiatedAction,
  IPayeeActions,
} from './types';

export const INITIAL_STATE: IPayeeState = {
  loading: false,
  payees: [],
  successful: false,
  error: null,
  newPayeedata: [],
  requestDeletePayee: {
    loading: false,
    message: '',
    deleted: null,
    error: null,
  },
  fetch: false,
};

export function* addpayeeRequest(action: IAddPayeeInitiatedAction) {
  try {
    const payee: IPayee[] = yield call(API.addPayee, action.payload);
    yield put(addPayeeSuccessful(payee));
  } catch (e) {
    yield put(addPayeeFailed(e));
  }
}

export function* AddPayee() {
  yield takeLatest(ADD_PAYEE_INITIATED, addpayeeRequest);
}

export function* ClearErrSuccess() {
  yield takeLatest(CLEAR_PAYEE_STATE, () => {});
}

export function* getPayeesRequest(action: IFetchPayeeInitiatedAction) {
  try {
    const payees: IPayee[] = yield call(API.getPayees);
    payees.sort((a: any, b: any) => (a.id < b.id ? 1 : -1));
    yield put(fetchPayeeSucceded(payees));
  } catch (e) {
    yield put(fetchPayeeFailed(e));
  }
}

export function* GetPayees() {
  yield takeLatest(FETCH_PAYEE_INITIATED, getPayeesRequest);
}

export function* getPayeeDeleteRequest(action: IDeletePayeeInitiatedAction) {
  try {
    const response: { message: string } = yield call(API.deletePayee, action.payload);

    yield put(deletePayeeSucceded({ message: response.message, deleted: true }));
  } catch (e) {
    yield put(deletePayeeFailed(e));
  }
}

export function* DeletePayee() {
  yield takeLatest(DELETE_PAYEE_INITIATED, getPayeeDeleteRequest);
}

export const selectPayee = (state: IApplicationState) => state.payee;

export default function reducer(state = INITIAL_STATE, action: IPayeeActions) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_PAYEE_INITIATED:
      return { ...state, loading: true };
    case FETCH_PAYEE_SUCCEEDED:
      return { ...state, loading: false, payees: payload as IPayee[], fetch: false };
    case FETCH_PAYEE_FAILED:
      return { ...state, loading: false };
    case DELETE_PAYEE_INITIATED:
      return {
        ...state,
        requestDeletePayee: {
          ...state.requestDeletePayee,
          deleted: false,
          loading: true,
          error: null,
        },
      };
    case DELETE_PAYEE_SUCCEEDED:
      return {
        ...state,
        requestDeletePayee: {
          ...state.requestDeletePayee,
          loading: false,
          ...(payload as {}),
        },
        fetch: true,
      };
    case DELETE_PAYEE_FAILED:
      return {
        ...state,
        requestDeletePayee: {
          ...state.requestDeletePayee,
          loading: false,
          deleted: false,
          error: payload as Error,
        },
      };
    case ADD_PAYEE_INITIATED:
      return { ...state, loading: true };
    case ADD_PAYEE_SUCCESSFUL:
      return {
        ...state,
        loading: true,
        successful: true,
        newPayeedata: action.payload.data?.data,
        fetch: true,
      };
    case ADD_PAYEE_FAILED:
      return {
        ...state,
        loading: false,
        successful: false,
        error: action.payload?.response?.data?.messages,
      };
    case CLEAR_PAYEE_STATE:
      return { ...state, loading: false, successful: false, error: null };
    default:
      return state;
  }
}
