import { useState, useEffect, useCallback } from 'react';
import OTPInput from './Otp';
import { Space, Button, notification, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './index.module.css';
import './types';
import { requestOtpInitiated, confirmOtpInitiated, clearOtp } from 'src/store/modules/auth/actions';
import { useSelector } from 'react-redux';
import { selectOtp } from '../../store/modules/auth';
import { requestTransactionOtpInitiated } from 'src/store/modules/transaction/actions';

interface IOTPProps {
  type: 'signup' | 'transaction';
  username: string;
}

const OTPComponent = (props: IOTPProps) => {
  const { type, username = '' } = props;
  const [seconds, setSeconds] = useState(59);
  const otpStatus = useSelector(selectOtp);
  const [otp, setOtp] = useState('');

  const openError = (errorMessage: string) => {
    notification.error({
      message: 'Error',
      description: `${errorMessage}`,
    });
  };

  const openNotification = (requestInfo: string) => {
    notification.success({
      message: 'Success',
      description: `${requestInfo}`,
    });
  };

  useEffect(() => {
    if (otpStatus.isSent && !otpStatus.isComplete) {
      openNotification('OTP Sent to Mail');
    } else if (otpStatus.isComplete) {
      openNotification('User confirmed');
    } else if (otpStatus.error) {
      openError(JSON.stringify(otpStatus.error?.message));
    }
  }, [otpStatus]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (seconds > 0) {
      timeout = setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    return () => clearTimeout(timeout);
  }, [seconds]);

  const dispatch = useDispatch();
  const clearOtpRequest = useCallback(() => {
    dispatch(clearOtp());
  }, [dispatch]);
  const requestSignupOtp = useCallback(() => {
    const otpRequest = { type, username };
    clearOtpRequest();
    dispatch(requestOtpInitiated(otpRequest));
  }, [clearOtpRequest, dispatch, type, username]);
  const requestTransactionOtp = useCallback(() => {
    dispatch(requestTransactionOtpInitiated());
  }, [dispatch]);

  useEffect(() => {
    const confirmRequest = { otp, username };

    if (otp.length === 6 && type === 'signup') {
      dispatch(confirmOtpInitiated(confirmRequest));
    }
  }, [dispatch, otp, username, type]);

  useEffect(() => {
    return () => {
      clearOtpRequest();
    };
  }, [clearOtpRequest]);

  return (
    <div className="App">
      <Space direction="vertical">
        <p className={styles.titleFont}>OTP email has been sent to {username}</p>
        <br></br>
        <p className={styles.textFont}>Please type the verification code in the mail</p>
        <Spin spinning={otpStatus.loading}>
          <div className={styles.otpDiv}>
            <OTPInput
              autoFocus
              inputStyle={{ margin: '4px' }}
              isNumberInput
              length={6}
              className={styles.otpContainer}
              inputClassName={styles.otpInput}
              onChangeOTP={(otp: string) => {
                setOtp(otp);
                return otp;
              }}
            />
          </div>
        </Spin>
        {seconds === 0 && (
          <div>
            <p className={styles.textFont}>Did'nt receive a code?</p>
            <Button
              type="primary"
              style={{ background: '#E0E0E0' }}
              className={styles.button}
              onClick={() => {
                setSeconds(59);
                type === 'signup' ? requestSignupOtp() : requestTransactionOtp();
              }}
            >
              Resend
            </Button>
          </div>
        )}
        {seconds > 0 && (
          <Button style={{ background: 'white', outline: 'none' }} className={styles.button}>
            <p className={styles.textFont}>{seconds} s </p>
          </Button>
        )}
      </Space>
    </div>
  );
};

export default OTPComponent;
