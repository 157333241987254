import React from 'react';
import { Modal as AntModal } from 'antd';

import Footer from 'src/components/Footer';
import './index.css';

interface IProps {
  children?: React.ReactNode;
  isVisible: boolean;
}

const defaultProps = {
  isVisible: false,
  bodyStyle: AntModal.defaultProps?.bodyStyle,
};

const Modal = (props: IProps & typeof defaultProps) => {
  return (
    <div className="authModal">
      <AntModal
        className="authModal__authModal"
        centered
        width={770}
        bodyStyle={{ padding: 0, ...props.bodyStyle }}
        visible={props.isVisible}
        closable={false}
        footer={null}
      >
        <div className="authModal__children">{props.children}</div>
        <Footer className="authModal__footer" />
      </AntModal>
    </div>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
