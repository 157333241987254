import { useEffect, useState, useCallback } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PasswordForm from './PassswordForm';
import { selectSignup } from 'src/store/modules/auth';
import { fetchCreateAccountInitiated, clearAccountCreation } from 'src/store/modules/auth/actions';
import registrationFormItems from './form';
import Welcome from './WelcomeForm';
import { getAuthError, TAuthError } from 'src/utils/Auth';

interface IProps {
  onNext: (email: string) => void;
  onFormChange: (values: {
    title?: string;
    subTitle?: string;
    formDescription?: string;
    onPrev: () => void;
  }) => void;
}
const defaultProps = {
  onNext: () => {},
};
interface IRegForm {
  company_name?: string;
  email?: string;
  invite_code?: string;
  password?: string;
  lastUpdatedForm?: 'PASSWORD_FORM' | 'DETAILS_FORM';
}
const forms = [
  { component: Welcome, form: registrationFormItems[0] },
  { component: PasswordForm, form: registrationFormItems[1] },
];

const RegistrationContent = ({ onFormChange, onNext }: IProps & typeof defaultProps) => {
  const { loading, error, email } = useSelector(selectSignup);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [regForm, setRegForm] = useState<IRegForm>({});
  const FormComponent = forms[currentFormIndex].component;

  const collectformData = useCallback((values: IRegForm = {}) => {
    setRegForm((prevValues) => ({ ...prevValues, ...values }));
  }, []);
  const next = useCallback(() => setCurrentFormIndex(1), []);
  const prev = useCallback(() => setCurrentFormIndex(0), []);
  const pickAuthError = useCallback(getAuthError, []);
  const createAccount = useCallback(
    (accountForm) => {
      dispatch(fetchCreateAccountInitiated(accountForm));
    },
    [dispatch],
  );
  const resetAcccountCreation = useCallback(() => {
    dispatch(clearAccountCreation());
  }, [dispatch]);

  useEffect(() => {
    if (regForm.lastUpdatedForm === 'DETAILS_FORM') next();
    else if (Object.keys(regForm).length) {
      createAccount(regForm);
    }
  }, [createAccount, next, regForm]);

  useEffect(() => {
    if (error) {
      const authError = pickAuthError(error as TAuthError);

      notification.error({
        message: `Request Error: ${authError}`,
        description: 'Kindly retry',
      });
    }

    return resetAcccountCreation;
  }, [error, pickAuthError, resetAcccountCreation]);

  useEffect(() => {
    if (email) {
      onNext(email);
    }

    return resetAcccountCreation;
  }, [email, onNext, resetAcccountCreation]);

  useEffect(() => {
    onFormChange({
      ...forms[currentFormIndex].form,
      onPrev: () => (currentFormIndex ? prev() : history.push('/login')),
    });
  }, [currentFormIndex, history, onFormChange, prev]);

  return (
    <FormComponent
      loading={loading}
      onFinish={collectformData}
      initialFormValues={regForm}
      formItem={forms[currentFormIndex].form}
    />
  );
};

RegistrationContent.defaultProps = defaultProps;

export default RegistrationContent;
