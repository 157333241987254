import { useState, useEffect, useCallback } from 'react';
import { Button, Typography } from 'antd';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import BackArrow from 'src/modules/theia/auth/component/BackArrow';
import style from './table.module.css';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

export interface ITransactionData {
  id: string;
  date: string;
  fromTo: string;
  paymentMethod: string;
  amount: string;
}

export interface IData {
  label: string;
  value: string;
}

interface TableProps {
  data: ITransactionData[];
  handleRowClick?(transactionId: string, data: IData[]): void;
}
interface MobileTableProps {
  data: ITransactionData[];
  showDetail(transactionId: string): void;
}

export const tableHeader = ['Date', 'From / To', 'Payment Method', 'Amount'];

export function Table({ data, handleRowClick }: TableProps) {
  const [tableData, setTableData] = useState(data);
  const [selectedRow, setSelectedRow] = useState('');
  const isMobile = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    const dataMap = new Map<string, ITransactionData[]>();

    data.forEach((info) => {
      const formatedData = format(new Date(info.date), 'MMMM, d');
      if (dataMap.has(formatedData)) {
        dataMap.get(formatedData)?.push({ ...info, date: '' });
      } else {
        const newInfo = { ...info };
        newInfo.date = formatedData;
        dataMap.set(formatedData, [newInfo]);
      }
    });

    let filteredData = new Array<ITransactionData>();
    Array.from(dataMap.entries()).forEach(([_, val]) => {
      if (val.length > 0) {
        filteredData = filteredData.concat(val);
      }
    });
    setTableData(filteredData);
  }, [data]);

  const showDetail = useCallback(
    (transactionId: string) => {
      if (!handleRowClick) return;
      const detail = data.find((item) => item.id === transactionId);
      if (!detail) return;
      const { id, ...rest } = detail;
      handleRowClick(
        id,
        Object.entries(rest).map(([label, value]) => ({
          label,
          value,
        })),
      );
      setSelectedRow(transactionId);
    },
    [handleRowClick, data],
  );

  return (
    <div className={style.container}>
      {isMobile ? (
        <MobileTable {...{ data, showDetail }} />
      ) : (
        <>
          <p className={style.count}>{`${data.length} Transactions`}</p>
          <table className={style.table}>
            <thead>
              <tr>
                {tableHeader.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map(({ id, date, fromTo, paymentMethod, amount }) => (
                <tr
                  key={id}
                  onClick={() => showDetail(id)}
                  data-active={id === selectedRow}
                  aria-selected={id === selectedRow}
                >
                  <td>{date}</td>
                  <td>{fromTo}</td>
                  <td className={style.payment}>{paymentMethod}</td>
                  <td className={style.amount}>
                    <span>{amount.substr(0, amount.length - 3)}</span>
                    <span className={style.decimal}>{amount.substr(amount.length - 3)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

function MobileTable({ data, showDetail }: MobileTableProps) {
  const { Title, Text } = Typography;

  return (
    <>
      <nav>
        <BackArrow classname="back-icon" />
        <Title level={4}>{data.length} Transaction</Title>
        <Button type="text" className={style['filter-btn']}>
          filter
        </Button>
      </nav>
      <div className="filters"></div>
      {data.map((item) => (
        <div className={style.row} onClick={() => showDetail(item.id)}>
          <div className={style.column}>
            <Text className={style.major} ellipsis>
              {item.fromTo}
            </Text>
            <span className={style.sub}>
              {formatDistanceToNow(new Date(item.date), { includeSeconds: true, addSuffix: true })}
            </span>
          </div>
          <div className="column">
            <Text className={style.major} ellipsis>
              {item.amount}
            </Text>
            <span className={style.sub}>sub-text</span>
          </div>
        </div>
      ))}
    </>
  );
}
