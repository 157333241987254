import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import {
  IRequestOtpInitiatedAction,
  IRequestOtpSucceededAction,
  IRequestOtpFailedAction,
  IOtpError,
} from './types';

export const requestTransactionOtpInitiated: ActionCreator<IRequestOtpInitiatedAction> = () => ({
  type: TYPES.REQUEST_OTP_INITIATED,
  payload: null,
});

export const requestOtpSucceded: ActionCreator<IRequestOtpSucceededAction> = () => ({
  type: TYPES.REQUEST_OTP_SUCCEEDED,
  payload: null,
});

export const requestOtpFailed: ActionCreator<IRequestOtpFailedAction> = (data: IOtpError) => ({
  type: TYPES.REQUEST_OTP_FAILED,
  payload: data,
});
