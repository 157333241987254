import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationState } from 'src/common/interface';
import { requestOtpSucceded, requestOtpFailed } from './actions';
import { REQUEST_OTP_INITIATED, REQUEST_OTP_SUCCEEDED, REQUEST_OTP_FAILED } from './actionTypes';
import API from './requests';
import { IRequestOtpInitiatedAction, ITransactionState, TransactionActions } from './types';

export const INITIAL_STATE: ITransactionState = {
  message: '',
  loading: false,
  error: null,
  isSent: false,
  isComplete: false,
};

export function* requestOtp(action: IRequestOtpInitiatedAction) {
  try {
    yield call(API.getTransactionOtp);

    yield put(requestOtpSucceded());
  } catch (e) {
    yield put(requestOtpFailed(e));
  }
}

export function* GetTransactionOtp() {
  yield takeLatest(REQUEST_OTP_INITIATED, requestOtp);
}

export const selectOtp = (state: IApplicationState) => state.transaction;

export default function reducer(state = INITIAL_STATE, action: TransactionActions) {
  const { payload } = action;
  switch (action.type) {
    case REQUEST_OTP_INITIATED:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_OTP_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: null,
        isSent: true,
      };
    case REQUEST_OTP_FAILED:
      return {
        ...state,
        loading: false,
        isSent: false,
        error: payload as Error,
      };
    default:
      return state;
  }
}
