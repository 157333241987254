import { useState, useEffect } from 'react';
import { IQuote } from 'src/store/modules/home/types';
import getCurrencyName from 'src/utils/currency';
import homeStyles from './Home.module.css';

const QuoteRow = ({ quote }: { quote: IQuote }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    loadImage(quote.currency);
  }, [quote.currency]);

  const loadImage = async (imageName: string) => {
    const importedImage = await import(`../../../../assets/flagIcon/${imageName}.png`);
    setImage(importedImage.default);
  };

  return (
    <tr key={quote.quoteid}>
      <td data-column="">
        <div className={homeStyles.imageWrapper}>
          <img src={image} alt={quote.currency} />
        </div>
      </td>
      <td data-column="">
        <div>
          <p>{quote.currency}</p>
          <small>{getCurrencyName(quote.currency)}</small>
        </div>
      </td>
      <td data-column="Buy" className={homeStyles.stretch}>
        <div>
          <p>
            ${' '}
            {Number(quote.buy).toLocaleString('en', {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            })}
          </p>
          <small></small>
        </div>
      </td>
      <td data-column="Sell" className={homeStyles.stretch}>
        <div>
          <p>
            ${' '}
            {Number(quote.sell).toLocaleString('en', {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            })}
          </p>
          <small></small>
        </div>
      </td>
    </tr>
  );
};

export default QuoteRow;
