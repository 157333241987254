import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationState } from 'src/common/interface';
import { fetchRatesFailed, fetchRatesSucceeded } from './actions';
import { FETCH_RATES_FAILED, FETCH_RATES_INITIATED, FETCH_RATES_SUCCEEDED } from './actionTypes';
import { getRates } from './requests';
import { IFetchRatesInitiatedAction, IRate, IRateActions, IRateState } from './types';

export const INITIAL_STATE: IRateState = {
  loading: false,
  rates: [],
};

export function* getRatesRequest(action: IFetchRatesInitiatedAction) {
  try {
    const rates: IRate[] = yield call(getRates);

    yield put(fetchRatesSucceeded(rates));
  } catch (e) {
    yield put(fetchRatesFailed());
  }
}
export function* GetRates() {
  yield takeLatest(FETCH_RATES_INITIATED, getRatesRequest);
}
export const selectRate = (state: IApplicationState) => state.rate;
export default function reducer(state = INITIAL_STATE, action: IRateActions) {
  switch (action.type) {
    case FETCH_RATES_INITIATED:
      return { ...state, loading: true };
    case FETCH_RATES_SUCCEEDED:
      return { ...state, loading: false, rates: action.payload as IRate[] };
    case FETCH_RATES_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
