import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { PublicPaths } from './index';
import { useAuthentication } from '../hooks/useAuth';

type RouteProps = {
  component: React.ComponentType<any>;
  path: string;
  exact: boolean;
};

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user, isAuthenticating } = useAuthentication();
  const isAuthenticated = user;

  return (
    <Route
      {...rest}
      render={(props) => {
        const content =
          isAuthenticating || isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={PublicPaths.LOGIN} />
          );
        return content;
      }}
    />
  );
};

export default PrivateRoute;
