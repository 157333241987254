import buttonStyles from './Button.module.css';
import { Button as AntBtn } from 'antd';

const defaultProps = {
  children: '',
  type: 'primary',
  className: '',
  loading: false,
  htmlType: 'button',
  disabled: false,
  logo: false,
  ...AntBtn.defaultProps,
};

interface ButtonProps {
  children?: string;
  type: 'primary' | 'secondary' | 'purple' | 'grey';
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  logo?: boolean;
}

const Button = ({ children, type, className, disabled, logo, loading, ...rest }: ButtonProps) => (
  <AntBtn
    {...rest}
    className={`${className} ${buttonStyles.btn} ${
      !disabled && !loading ? buttonStyles[type] : ''
    } ${disabled || loading ? buttonStyles.disabled : ''}`}
    disabled={disabled || loading}
    loading={loading}
  >
    {children}
    {logo && !loading ? (
      <div className={buttonStyles.logoContainer}>
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
          <path
            d="M1 1L6 6L1 11"
            stroke={disabled || loading || type === 'secondary' ? '#191A32' : 'white'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null}
  </AntBtn>
);

Button.defaultProps = defaultProps;

export default Button;
