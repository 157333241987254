import { formItem } from './types';

const passwordValidations = [
  {
    name: 'at least 10 characters',
    regExp: '.{10,}',
  },
  {
    name: 'at least 1 number',
    regExp: '.*[0-9].*',
  },
  {
    name: 'at least 1 punctuation character',
    regExp: '.*[^A-Za-z0-9].*',
  },
  {
    name: 'at least 1 uppercase character',
    regExp: '.*[A-Z].*',
  },
  {
    name: 'at least 1 lowercase character',
    regExp: '.*[a-z].*',
  },
];

const formItems: formItem[] = [
  {
    fields: [
      {
        name: 'username',
        type: 'text',
        label: 'Username',
        placeholder: 'email',
        rules: [{ required: true, message: '' }],
      },
    ],
    button: {
      text: 'Request code',
    },
    action: 'REQUEST_CODE',
    title: 'Recover Password',
    subTitle: 'Enter your username',
    formDescription: 'A reset code will be sent to the provided email',
  },
  {
    fields: [
      {
        name: 'code',
        type: 'number',
        label: 'Code',
        placeholder: 'e.g 101029',
        rules: [{ required: true, message: '' }],
      },
      {
        name: 'password',
        type: 'password',
        label: 'Password',
        placeholder: 'password',
        rules: [
          { required: true, message: '' },
          ...passwordValidations.map(({ name, regExp }) => ({
            name,
            validator: async (_: any, input: string) => {
              const regex = new RegExp(regExp);
              if (!regex.test(input)) {
                return Promise.reject();
              }
            },
          })),
        ],
      },
      {
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm password',
        placeholder: 'confirm password',
        dependencies: ['password'],
        rules: [
          { required: true, message: '' },
          ({ getFieldValue }: { getFieldValue: (field: string) => string }) => ({
            validator(_: unknown, value: unknown) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
          }),
        ],
      },
    ],
    button: {
      text: 'Reset password',
    },
    action: 'RESET_PASSSWORD',
    title: 'Reset Password',
    subTitle: 'Complete your password recovery',
    formDescription: 'Enter code sent to your email',
  },
];

export default formItems;
