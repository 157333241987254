import { useState, useEffect } from 'react';

/**
 * Custom use media query hook
 * 
 * @example
 * function Page() {
    let isPageWide = useMediaQuery('(min-width: 800px)')
    
    return <>
      {isPageWide && <UnnecessarySidebar />}
      <ImportantContent />
    </>
  }
 * @param query Query string in the format "(min-width: 768px)"
 * @returns boolean
 */
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}
