import { all } from 'redux-saga/effects';
import { ConfirmOtp, GetAuth, GetOtp } from './modules/auth';
import { GetTransactionOtp } from './modules/transaction';
import { GetHome } from './modules/home';
import { GetBalances } from './modules/balance';
import { GetPayees, DeletePayee, AddPayee } from 'src/store/modules/payee/index';
import { GetRates } from './modules/rate';

export default function* rootSaga() {
  yield all([
    GetAuth(),
    GetOtp(),
    ConfirmOtp(),
    GetTransactionOtp,
    GetHome(),
    GetBalances(),
    GetPayees(),
    DeletePayee(),
    AddPayee(),
    GetRates(),
  ]);
}
