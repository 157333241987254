import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Checkbox } from 'antd';
import { useParams } from 'react-router';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CustomBtn from 'src/components/Button';
import RenderHtml from 'src/components/RenderHtml';

import { selectSignup } from 'src/store/modules/auth';
import { acceptTermsAndConditions, clearAccountCreation } from 'src/store/modules/auth/actions';
import { terms } from 'src/aws-exports';
import styles from './terms.module.css';

interface IProps {
  onNext: (username: string) => void;
  onFormChange: (values: { title?: string; subTitle?: string; formDescription?: string }) => void;
}
const defaultProps = {
  onNext: () => {},
};

const TermsAndConditions = ({ onFormChange, onNext }: IProps & typeof defaultProps) => {
  const { username } = useParams<{ username: string }>();
  const { terms: isTermChecked } = useSelector(selectSignup);
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handlechange = (event: CheckboxChangeEvent) => {
    setChecked(event.target.checked);
  };

  const updateTermsAndConditions = useCallback(() => {
    dispatch(acceptTermsAndConditions());
  }, [dispatch]);

  const resetAcccountCreation = useCallback(() => {
    dispatch(clearAccountCreation());
  }, [dispatch]);

  useEffect(() => {
    resetAcccountCreation();
  }, [resetAcccountCreation]);

  useEffect(() => {
    onFormChange({ title: 'Terms & Conditions' });
  }, [onFormChange]);

  useEffect(() => {
    if (isTermChecked && checked) {
      onNext(username);
    }
  }, [checked, isTermChecked, onNext, username]);

  return (
    <div>
      <section>
        <RenderHtml className={styles.terms} data={terms} />
      </section>
      <Form onFinish={updateTermsAndConditions} className={styles.form}>
        <div className={styles.checkboxContainer}>
          <Checkbox checked={checked} onChange={handlechange} />
          <div className={styles.termsDescriptionContainer}>
            <p className={styles.termsDescription1}> By clicking the tick button,</p>
            <span className={styles.termsDescription2}>
              I confirm I have read and accept the terms and conditions
            </span>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <CustomBtn className={styles.btn} disabled={!checked} logo htmlType="submit">
            Next
          </CustomBtn>
        </div>
      </Form>
    </div>
  );
};

export default TermsAndConditions;
