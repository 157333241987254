import styles from './index.module.css';

const defaultProps = {
  style: {},
  className: '',
};

interface IProps {
  style?: { [index: string]: unknown };
  className?: string;
}

const AuthFooter = (props: IProps & typeof defaultProps) => (
  <footer {...props} className={`${props.className} ${styles.footer}`}>
    <div className={styles.footerContainer}>
      <div className={styles.footerDiv1}>
        <a className={styles.footerLinks} href="/terms">
          Terms & conditions
        </a>
      </div>
      <div className={styles.footerDiv2}>
        <a className={styles.footerLinks} href="/#">
          Disclaimer & Internet Privacy Statement
        </a>
      </div>
      <div className={styles.footerDiv3}>
        © 2020 Fin is made with🖤 by Pecutus Technologies Ltd with FX services provided by Ngau Kee
        Money Changer Co. Ltd , a Hong Kong Customs and Excise Department licensed Money Service
        Operator
      </div>
    </div>
  </footer>
);

AuthFooter.defaultProps = defaultProps;

export default AuthFooter;
