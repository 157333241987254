import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DesktopLayoutStructure, MobileLayoutStructure, FXSider, FXContent } from './components';

import { fetchSignOutInitiated } from 'src/store/modules/auth/actions';

import 'antd/dist/antd.css';
import styles from './index.module.css';

interface IProps {
  children?: React.ReactNode;
}

const RDFXLayout = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleMenuClick = (path: string) => {
    history.push(path);
  };

  const handleSignOut = useCallback(() => {
    dispatch(fetchSignOutInitiated());
  }, [dispatch]);

  const [collapse, setCollapse] = useState(false);
  const [broken, setBroken] = useState(false);

  const SiderTitle = <div className={styles.sider_title}>Pecutus Technologies limited</div>;
  const Sider = (
    <FXSider
      collapse={collapse}
      setCollapse={setCollapse}
      handleSignOut={handleSignOut}
      handleMenuClick={handleMenuClick}
      setBroken={setBroken}
      Title={broken ? SiderTitle : null}
    />
  );

  const Layout = broken ? (
    <MobileLayoutStructure
      Sider={Sider}
      Content={<FXContent children={props.children} />}
      collapse={collapse}
      setCollapse={setCollapse}
    />
  ) : (
    <DesktopLayoutStructure Sider={Sider} Content={<FXContent children={props.children} />} />
  );

  return <>{Layout}</>;
};

export default RDFXLayout;
