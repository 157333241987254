import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PayeeCard from './PayeeCard';
import SkeletonCard from './SkeletonCard';
import { Row, Col } from 'antd';
import './index.css';
import styles from 'src/modules/payee/components/Payee/Payee.module.css';
import { Form, Input, Button } from 'antd';
import AddPayeeModal from './PayeeModal';
import { SearchOutlined } from '@ant-design/icons';

import Paginator from '../../../../components/Paginator';
import { openNotification, openError } from '../../../../utils/common';
import { fetchPayeeInitiated, deletePayeeInitiated } from '../../../../store/modules/payee/actions';
import { selectPayee } from '../../../../store/modules/payee';
import { IPayee } from '../../../../store/modules/payee/types';

const Payee = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [itemsPerPage] = useState(12);
  const [visible, setVisible] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResult, setSearchResult] = useState<IPayee[]>([]);
  const [form] = Form.useForm();

  const { innerWidth: width } = window;
  const isMobile = width <= 767;
  const dispatch = useDispatch();
  const { payees, loading, requestDeletePayee, fetch } = useSelector(selectPayee);
  const nodeRef = useRef(null);

  useEffect(() => {
    dispatch(fetchPayeeInitiated());
  }, [dispatch]);

  useEffect(() => {
    if (fetch) dispatch(fetchPayeeInitiated());
  }, [dispatch, fetch]);

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (payees) {
      const searchPayee = payees?.filter(
        (payee: IPayee) =>
          payee.name.toLowerCase().includes(searchInputValue) ||
          payee?.nickname?.toLowerCase().includes(searchInputValue),
      ) as IPayee[];

      const pageCount =
        searchPayee.length % itemsPerPage
          ? Math.floor(searchPayee.length / itemsPerPage) + 1
          : Math.floor(searchPayee.length / itemsPerPage);
      setPages(pageCount);
      setSearchResult(searchPayee);

      // if user deletes all record on page 2 then conrrect the page number
      if (page > pageCount) {
        setPage(1);
      }
    }
  }, [searchInputValue, payees, itemsPerPage, page]);

  useEffect(() => {
    if (requestDeletePayee.deleted) openNotification(requestDeletePayee.message);
  }, [dispatch, requestDeletePayee.deleted, requestDeletePayee.message]);

  useEffect(() => {
    if (requestDeletePayee.error) openError(requestDeletePayee.error.message);
  }, [dispatch, requestDeletePayee.error]);

  const handleDeletePayee = useCallback(
    (id: string) => {
      dispatch(deletePayeeInitiated(id));
    },
    [dispatch],
  );

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchInputValue(e.currentTarget.value.toLowerCase());
  };

  const handleModalCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const searchInputBoxJSX = (
    <Input
      allowClear
      className={styles.actionItemsContainer_searchbox}
      placeholder="Search"
      prefix={<SearchOutlined />}
      value={searchInputValue}
      onChange={(e) => handleSearch(e)}
    />
  );

  return (
    <>
      <div className={styles.topnavbar}>
        <h1 className={styles.topnavbar__payeeText}>Payees</h1>
        <div className={styles.topnavbar__actionItemsContainer}>
          {!isMobile && searchInputBoxJSX}
          <Button
            ref={nodeRef}
            className={styles.actionItemsContainer__addPayeeBtn}
            onClick={showModal}
          >
            <span className={styles.addPayeeBtn__text}>Add Payee</span>
          </Button>
        </div>
        <AddPayeeModal isModalvisible={visible} handleCancel={handleModalCancel} form={form} />
      </div>
      {isMobile && searchInputBoxJSX}
      <section style={{ height: '100%' }}>
        <Row gutter={[20, 30]} className="row__payeeCard" justify={'start'}>
          {loading && <SkeletonCard />}
          {!loading &&
            searchResult?.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((payee, i) => {
              return (
                <Col
                  key={i}
                  xl={8}
                  className="col__payeeCard payeeCard__alignment_left payeeCard-col-sm-24"
                >
                  <PayeeCard payee={payee} handleDeletePayee={handleDeletePayee} />
                </Col>
              );
            })}
        </Row>
        <Paginator
          setPage={setPage}
          page={page}
          pages={pages}
          itemsPerPage={itemsPerPage}
          items={searchResult}
        />
      </section>
    </>
  );
};

export default React.memo(Payee);
