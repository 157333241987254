import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { DefaultFormInput } from 'src/components/CustomInputs';
import CustomBtn from 'src/components/Button';
import PasswordStrength from '../PasswordStrength';
import styles from './registration.module.css';
import { IValidations } from '../ForgetPasswordModal/types';
import { IFormItem } from './form';

interface IProps {
  formItem: IFormItem;
  loading: boolean;
  onFinish: (formValues: { [idex: string]: unknown }) => void;
}
interface IFormError {
  [index: string]: string[];
}
type TFormError = IFormError | null;
const defaultProps = {
  formItem: {},
  loading: false,
};

const PasswordForm = ({ formItem, onFinish, loading }: IProps & typeof defaultProps) => {
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [password, setPassword] = useState<string>('');
  const [validations, setValidations] = useState<IValidations[]>([]);
  const [form] = Form.useForm();

  const handleFormChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object.keys(errors).length ? errors : null);
    setPassword(form.getFieldValue('password'));
  }, [form]);

  useEffect(() => {
    let availableValidations: IValidations[] = [];

    if (formItem.fields && formItem.fields[0]) {
      if (formItem.fields[0].rules) {
        formItem.fields[0].rules.forEach(({ validator, name = '' }) => {
          if (validator) availableValidations.push({ validator, name });
        });
      }
      setValidations(availableValidations);
    }
  }, [formItem, formItem.fields]);

  const handleFinish = (values: { [index: string]: unknown }) => {
    onFinish({
      ...values,
      lastUpdatedForm: 'PASSWORD_FORM',
    });
  };

  return (
    <Form onFinish={handleFinish} form={form} onFieldsChange={handleFormChange}>
      {(formItem.fields || []).map((item) => (
        <Form.Item
          {...(item.dependencies ? { dependencies: item.dependencies } : {})}
          key={`passwordForm-item-${item?.name}`}
          name={item?.name}
          rules={item?.rules}
          className={styles.formItem}
        >
          <DefaultFormInput
            error={formError ? !!formError[item.name] : false}
            label={item?.label}
            key={`passwordForm-input-${item?.name}`}
            type={item.type}
            placeholder={item?.placeholder}
          />
        </Form.Item>
      ))}
      <PasswordStrength password={password} rules={validations} />
      <div className={styles.createAccountBtnContainer}>
        <CustomBtn
          loading={loading}
          className={styles.createAccountBtn}
          disabled={!!formError}
          htmlType="submit"
        >
          Create your account
        </CustomBtn>
      </div>
    </Form>
  );
};

PasswordForm.defaultProps = defaultProps;

export default PasswordForm;
