import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { notification } from 'antd';

import ForgetPasswordModal from './ForgetPasswordModal';
import formItems from './forms';
import { selectResetPassword } from '../../../../store/modules/auth';
import {
  fetchPasswordResetInitiated,
  fetchPasswordResetConfirmationInitiated,
  clearResetPassword,
} from '../../../../store/modules/auth/actions';
import { getAuthError, TAuthError } from 'src/utils/Auth';

interface IProps {
  isVisible: boolean;
}

const defaultProps = {
  isVisible: false,
};

const Modal = (props: IProps & typeof defaultProps) => {
  const { username } = useParams<{ username: string }>();
  const history = useHistory();
  const selectPasswordState = useSelector(selectResetPassword);
  const dispatch = useDispatch();
  const [currentFormItem, setCurrentFormItem] = useState(formItems[0]);

  const { loading, isComplete, username: resetUsername, error } = selectPasswordState;

  const initiateresetRequest = useCallback(
    (username: string) => {
      dispatch(fetchPasswordResetInitiated(username));
    },
    [dispatch],
  );

  const confirmResetRequest = useCallback(
    (data: { code: string; password: string; username: string }) => {
      dispatch(fetchPasswordResetConfirmationInitiated(data));
    },
    [dispatch],
  );

  const clearResetPasswordRequest = useCallback(() => {
    dispatch(clearResetPassword());
  }, [dispatch]);

  const onFinish = (values?: any) => {
    if (currentFormItem.action === 'REQUEST_CODE') {
      initiateresetRequest(values.username);
    } else {
      const data = {
        username: username,
        password: values.password,
        code: values.code,
      };
      confirmResetRequest(data);
    }
  };
  const handleBackArrowClick = () => {
    if (currentFormItem.action === 'REQUEST_CODE') {
      history.push('/login');
    } else {
      history.push(`/reset_password/${resetUsername}`);
    }
  };

  const pickAuthError = useCallback(getAuthError, []);
  const renderNext = () => setCurrentFormItem(() => formItems[1]);

  useEffect(() => {
    resetUsername && history.push(`/reset_password/${resetUsername}`);

    return () => clearResetPasswordRequest();
  }, [clearResetPasswordRequest, history, resetUsername]);

  useEffect(() => {
    username && renderNext();
  }, [username]);

  useEffect(() => {
    if (error) {
      const authError = pickAuthError(error as TAuthError);

      notification.error({
        message: `Request Error: ${authError}`,
        description: 'Kindly retry',
      });

      if (authError === 'Username/client id combination not found.')
        history.push('/reset_password/');
    }
    return () => clearResetPasswordRequest();
  }, [clearResetPasswordRequest, error, history, pickAuthError]);

  useEffect(() => {
    if (isComplete) {
      notification.success({
        message: 'Password reset Successful',
        description: 'Kindly login with your new password',
      });

      history.push('/login');
    }
    return () => clearResetPasswordRequest();
  }, [clearResetPasswordRequest, history, isComplete]);

  return (
    <ForgetPasswordModal
      onFinish={onFinish}
      isLoading={loading}
      formItems={currentFormItem}
      onBackArrowClick={handleBackArrowClick}
      {...props}
    />
  );
};

export default Modal;
