import React from 'react';
import { terms } from 'src/aws-exports';
import RenderHtml from 'src/components/RenderHtml';

import styles from './index.module.css';

const Terms = () => {
  return <RenderHtml className={styles.terms} data={terms} />;
};

export default React.memo(Terms);
