import React from 'react';
import { Card, Skeleton } from 'antd';
import { Col } from 'antd';
const { Meta } = Card;

const SkeletonCard = () => {
  return (
    <>
      {Array(12)
        .fill(0)
        .map((payee, index) => (
          <Col
            key={index}
            xl={8}
            className="col__payeeCard payeeCard__alignment_left payeeCard-col-sm-24"
          >
            <Card className="payeeCard">
              <Skeleton paragraph={{ rows: 2, width: [200, 100] }} loading={true} active>
                <Meta title="" description="" />
              </Skeleton>
            </Card>
          </Col>
        ))}
    </>
  );
};

export default SkeletonCard;
