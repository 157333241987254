import request from 'src/utils/request';

export const getPayees = async () => {
  const response = await request.get('/user/query/payee');

  if (response.status === 200 && response.data.success) {
    return response.data.data;
  }
  return [];
};

export const deletePayee = async (id: string) => {
  const response = await request.delete(`/user/payee/?id=${id}`);

  return response.data;
};

const addPayee = async (data: unknown) => await request.put('user/payee/', data);

const actions = {
  getPayees,
  deletePayee,
  addPayee,
};

export default actions;
