import React, { useState } from 'react';
import { Card } from 'antd';
import { Row, Col, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { IPayee } from 'src/store/modules/payee/types';
import PayeeCardHead from './Header';

const PayeeCard = ({
  payee,
  handleDeletePayee,
}: {
  payee: IPayee;
  handleDeletePayee: (id: string) => void;
}) => {
  const [deleting, setDeleting] = useState(false);
  return (
    <Card
      className="payeeCard"
      title={
        <PayeeCardHead
          id={payee.id}
          name={payee.name}
          nickname={payee.nickname}
          handleDeletePayee={handleDeletePayee}
          setLoading={setDeleting}
        />
      }
      headStyle={{ border: 0 }}
    >
      <Spin spinning={deleting}>
        <div className="payeeCard__body">
          <Row>
            <Col span={23}>
              <Row>
                <Col span={12} className="bankname">
                  {payee.nickname}
                </Col>
                <Col span={12} className="bankaccount">
                  {payee.bank_account}
                </Col>
              </Row>
              <Row>
                <Col span={24}>{payee.bank_address}</Col>
              </Row>
            </Col>
            <Col span={1} className="close-mobile-icon-container">
              <CloseOutlined
                className="payeeCard__head-icon payeeCard__head-icon-alignment"
                onClick={() => {
                  setDeleting(true);
                  handleDeletePayee(payee.id);
                }}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </Card>
  );
};

export default PayeeCard;
