import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { selectAuth } from '../store/modules/auth';

import { getAuthError, TAuthError } from '../utils/Auth';
import { User } from '../common/interface';

interface IAuth {
  user: User | null;
  isAuthenticating: boolean;
}

export const useAuthentication = () => {
  const history = useHistory();
  const location = useLocation();

  const { user } = useSelector(selectAuth);
  const [auth, setAuth] = useState<IAuth>({
    user,
    isAuthenticating: true,
  });
  const [error, setError] = useState<unknown | null>(null);

  const getAuth = useCallback(async () => {
    try {
      await Auth.currentSession();
      const response = await Auth.currentUserInfo();

      setAuth((prevState) => ({
        ...prevState,
        user: response.attributes,
        isAuthenticating: false,
      }));
    } catch (err) {
      setError(err);
    }
  }, []);

  const pickAuthError = useCallback(getAuthError, []);

  useEffect(() => {
    if (error) {
      const authError = pickAuthError(error as TAuthError);
      const shouldNotify = location.pathname !== '/login' && authError !== 'No current user';

      if (shouldNotify) {
        notification.error({
          message: `Request Error: ${authError}`,
          description: 'please login again',
        });
      }

      setAuth({
        user: null,
        isAuthenticating: false,
      });
    }
  }, [error, history, location.pathname, pickAuthError]);

  useEffect(() => {
    setAuth((prevState) => ({ ...prevState, isAuthenticating: true }));
    getAuth();
  }, [getAuth, history, user]);

  return auth;
};
