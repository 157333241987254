import { createBrowserHistory } from 'history';
import { Switch, Router, Redirect } from 'react-router-dom';

import Auth from '../modules/auth/pages/auth';
import Terms from '../modules/agreement/pages/terms';
import DashboardRoutes from './DashboardRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoutes from './PublicRoute';
import TheiaAuth from '../modules/theia/auth/pages/theia_auth';

export const PublicPaths = {
  LOGIN: '/login',
  RESET_PASSWORD_VERIFY: '/reset_password/:username',
  RESET_PASSWORD: '/reset_password',
  SIGNUP: '/signup',
  SIGNUP_TERMS: '/signup/terms/:username',
  TERMS: '/terms',
  VERIFY: '/signup/verify/:username',
  THEIA_SIGNUP: '/theia-signup',
  THEIA_SETPASSWORD: '/theia-signup/theia-setpassword',
  THEIA_VERIFY: '/theia-signup/theia-verify',
  THEIA_LOGIN: '/theia-login',
};

const publicRoutes = [
  { path: PublicPaths.LOGIN, exact: true, component: Auth },
  { path: PublicPaths.RESET_PASSWORD, exact: true, component: Auth },
  { path: PublicPaths.RESET_PASSWORD_VERIFY, exact: true, component: Auth },
  { path: PublicPaths.SIGNUP, exact: true, component: Auth },
  { path: PublicPaths['SIGNUP_TERMS'], exact: true, component: Auth },
  { path: PublicPaths.TERMS, exact: true, component: Terms },
  { path: PublicPaths.VERIFY, exact: true, component: Auth },
  { path: PublicPaths.THEIA_SIGNUP, exact: true, component: TheiaAuth },
  { path: PublicPaths.THEIA_SETPASSWORD, exact: true, component: TheiaAuth },
  { path: PublicPaths.THEIA_VERIFY, exact: true, component: TheiaAuth },
  { path: PublicPaths.THEIA_LOGIN, exact: true, component: TheiaAuth },
];

const Routes = () => {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Switch>
        {publicRoutes.map((route, index) => (
          <PublicRoutes
            key={index}
            component={route.component}
            path={route.path}
            exact={route.exact}
          />
        ))}
        <PrivateRoute exact={false} path={'/'} component={DashboardRoutes} />
        <Redirect to={PublicPaths.LOGIN} />
      </Switch>
    </Router>
  );
};

export default Routes;
