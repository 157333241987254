import config from './config';

const env = (process.env.REACT_APP_ENV as keyof typeof config) || 'GITSTART';
const envConfig = config[env];

if (!envConfig) {
  throw new Error(`Unknown environment ${env}`);
}

export const endpoint = envConfig.endpoint;
export const terms = envConfig.terms;
export default envConfig.awsConfig;
