import { useState } from 'react';
import { Form, Typography } from 'antd';
import CancelIcon from 'src/components/CancelIcon';
import CustomBtn from 'src/components/Button';
import { RegFormItem } from './form';
import styles from './registration.module.css';
import { Link } from 'react-router-dom';
import { DefaultFormInput } from 'src/components/CustomInputs';
import { IProps } from 'src/modules/theia/util/types';
import { TFormError } from 'src/modules/auth/components/ForgetPasswordModal/types';
import {
  useHandleFormChange,
  useHandleFinish,
} from 'src/modules/theia/auth/component/modules/signup/form';

const { Paragraph, Text, Link: TypoLink } = Typography;

interface ITheiaRegForm {
  company_name?: string;
  email?: string;
  phone_number?: string;
  invite_code?: string;
}

const Registration = (props: IProps) => {
  const [regForm, setRegForm] = useState<ITheiaRegForm>({});
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();
  const handleFormChange = useHandleFormChange(form, setFormErrors);
  const handleFinish = useHandleFinish<ITheiaRegForm>(setRegForm, props);

  return (
    <div className={styles.regFormWrapper} data-testid="theia-registration">
      <CancelIcon onClick={props.onBack} classname={styles.posLeft} />
      <Text className={styles.regTitle}>
        Get started by <br /> creating your account
      </Text>

      <Form
        onFinish={(values) => handleFinish({ ...values })}
        form={form}
        className={styles.form}
        onFieldsChange={handleFormChange}
      >
        <div>
          {(RegFormItem.fields || []).map((item) => (
            <Form.Item
              key={`welcomeForm-${item.name}`}
              initialValue={regForm[item?.name]}
              className={styles.formItem}
              name={item?.name}
              rules={item?.rules}
            >
              <DefaultFormInput
                error={formError ? !!formError[item.name] : false}
                label={(item?.label || '').toLowerCase()}
                labelClassName={styles.label}
                type={item.type}
                placeholder={item?.placeholder}
                data-testid={`reg_${item.name}`}
              />
            </Form.Item>
          ))}
        </div>
        <Typography className={styles.terms}>
          <Paragraph>
            You agree to our <Link to="/#" component={TypoLink} children="terms" /> by clicking
          </Paragraph>
        </Typography>
        <CustomBtn disabled={!!formError} className={styles.btn} htmlType="submit" logo={false}>
          Create your account
        </CustomBtn>
      </Form>
    </div>
  );
};

export default Registration;
