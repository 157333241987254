import { notification } from 'antd';

export const openNotification = (description: string) => {
  notification.success({
    message: 'Success',
    description: `${description}`,
  });
};

export const openError = (errorDescription: string) => {
  notification.error({
    message: 'Error',
    description: `${errorDescription}`,
  });
};

export const toLocaleFixed = (num: number, n: number) => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
  });
};

export const obscureCardNumber = (number: string, obscureLength: number) => {
  const re = new RegExp(`\\d(?=\\d{${obscureLength}})`, 'g');
  let obscruedString = number.replace(re, '*');
  let ind = obscruedString.lastIndexOf('*');
  obscruedString = `${obscruedString.substring(0, ind)} ${obscruedString.substring(ind + 1)}`;
  return obscruedString;
};

export const camelCaseToCapitalizedWord = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
