import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import AuthModal from '../AuthModal';
import './signup.css';
import RegistrationContent from './Registration';
import TermsAndConditions from './TermsAndConditions';
import AuthFormWrapper from '../AuthFormWrapper';
import Verification from './Verification';
import { selectSignup } from 'src/store/modules/auth';
interface IProps {
  isVisible: boolean;
}
const defaultProps = {
  isVisible: false,
};

const SIGNUP = '/signup';
const TERMS = '/signup/terms';
const VERIFY = '/signup/verify';

const getIndexFromPath = (pathname: string) => {
  switch (true) {
    case pathname.includes(TERMS):
      return 1;
    case pathname.includes(VERIFY):
      return 2;
    default:
      return 0;
  }
};

const steps = [
  {
    title: 'Sign up',
    content: RegistrationContent,
    wrapperClass: '',
    url: () => SIGNUP,
    index: 0,
  },
  {
    title: 'Terms & conditions',
    content: TermsAndConditions,
    wrapperClass: '',
    url: (username: string) => `${TERMS}/${username}`,
    index: 1,
  },
  {
    title: 'Verification',
    content: Verification,
    wrapperClass: 'signup_form',
    url: (username: string) => `${VERIFY}/${username}`,
    index: 2,
  },
];

const SignupModal = (props: IProps & typeof defaultProps) => {
  const history = useHistory();
  const path = history.location.pathname;
  const { terms } = useSelector(selectSignup);
  const [current, setCurrent] = useState(getIndexFromPath(path));
  const [formItem, setFormItem] = useState<{
    title?: string;
    subTitle?: string;
    formDescription?: string;
    showBackArrow?: boolean;
    onPrev?: () => void;
  }>({});

  const next = useCallback(
    (username?: string) => {
      if (current >= 2) {
        return history.push('/login');
      }
      const url = steps[current + 1].url(username || '');
      history.push(url);
    },
    [current, history],
  );

  const getFormTitles = useCallback(
    (values: { title?: string; subTitle?: string; formDescription?: string }) =>
      setFormItem(values),
    [],
  );

  useEffect(() => {
    const pathIndex = getIndexFromPath(path);
    setCurrent(pathIndex === 2 && !terms ? 1 : pathIndex);
  }, [history.location.pathname, path, terms]);

  const ModalComponent = steps[current].content;

  return (
    <AuthModal isVisible={props.isVisible}>
      <AuthFormWrapper
        steps={steps}
        currentStep={current}
        formBodyClass={steps[current].wrapperClass}
        showHeader
        showStepper
        showBackArrow={!!formItem.showBackArrow}
        title={formItem.title}
        subTitle={formItem.subTitle}
        onBackarrowClick={() => formItem.onPrev && formItem.onPrev()}
        formDescription={formItem.formDescription}
      >
        <div className="steps-content">
          {<ModalComponent onNext={next} onFormChange={getFormTitles} />}
        </div>
      </AuthFormWrapper>
    </AuthModal>
  );
};

SignupModal.defaultProps = defaultProps;

export default SignupModal;
