import ReactPhoneInput, { PhoneInputProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from './index.module.css';

interface IProps {
  error?: boolean;
}

const PhoneInput = ({ error, ...props }: IProps & PhoneInputProps) => {
  return (
    <ReactPhoneInput className={`${styles.phoneInput} ${error ? styles.error : ''}`} {...props} />
  );
};

export default PhoneInput;
