import { Button, Row, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import balanceStyles from '../components/Balance/Balance.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalancesInitiated } from 'src/store/modules/balance/actions';
import { selectBalance } from 'src/store/modules/balance';
import BalanceRow from '../components/Balance/BalanceRow';
import { selectRate } from 'src/store/modules/rate';
import { fetchRatesInitiated } from 'src/store/modules/rate/actions';
import { toLocaleFixed } from 'src/utils/common';

const Balance = () => {
  const dispatch = useDispatch();
  const userBalance = useSelector(selectBalance);
  const rate = useSelector(selectRate);
  const [availableTotal, setAvailableTotal] = useState(0);
  const [availableLedger, setAvailableLedger] = useState(0);
  const loading = userBalance?.loading;
  const rates = rate?.rates;
  const balances = userBalance?.balances;

  const findExchangeRate = useCallback(
    (currency: string) => {
      let currObj = rate.rates?.find((element) => {
        return element.currency === currency;
      });
      return currObj?.sell || 0;
    },
    [rate.rates],
  );

  useEffect(() => {
    dispatch(fetchBalancesInitiated());
    dispatch(fetchRatesInitiated());
  }, [dispatch]);

  useEffect(() => {
    if (rates?.length !== 0 && balances?.length !== 0) {
      balances?.forEach((element) => {
        element.local_available = element.available * findExchangeRate(element.currency);
        element.local_ledger = element.ledger * findExchangeRate(element.currency);
      });
      const sumAvailable = balances?.reduce(
        (accumulator, current) => accumulator + current.local_available,
        0,
      );
      setAvailableTotal(sumAvailable ? sumAvailable : 0);
      const sumLedger = balances?.reduce(
        (accumulator, current) => accumulator + current.local_ledger,
        0,
      );
      setAvailableLedger(sumLedger ? sumLedger : 0);
    }
  }, [findExchangeRate, rates, balances]);

  return (
    <>
      <section className={balanceStyles.balances}>
        <Row className={balanceStyles.balances_border}>
          <div className={balanceStyles.balances_head}>
            <h1 className={balanceStyles.balances_title}>Assets Summary (in HKD)</h1>
          </div>
        </Row>
        <div className={balanceStyles.balances_border}>
          <div className={balanceStyles.balances_border_vertical}>
            <h1 className={balanceStyles.balances_sub_title}>Ledger Balance</h1>
            <h1 className={balanceStyles.balances_amount}>
              <BalanceAmount balance={availableLedger} />
            </h1>
          </div>
          <div className={balanceStyles.balances_border_vertical_last}>
            <h1 className={balanceStyles.balances_sub_title}>Available Balance</h1>
            <h1 className={balanceStyles.balances_amount}>
              <BalanceAmount balance={availableTotal} />
            </h1>
          </div>
        </div>
        <div className={balanceStyles.balances_button_container}>
          <Button
            className={balanceStyles.balances_topup_button}
            style={{ background: 'white', border: '1px solid', borderRadius: '5px' }}
          >
            Top-up balance
          </Button>
          <Button
            className={balanceStyles.balances_withdraw_button}
            style={{
              background: 'black',
              border: '1px solid',
              color: 'white',
              borderRadius: '5px',
            }}
          >
            Transfer
          </Button>
          <Button
            className={balanceStyles.balances_transfer_button}
            style={{
              background: 'black',
              border: '1px solid',
              color: 'white',
              borderRadius: '5px',
            }}
          >
            Withdraw
          </Button>
        </div>
      </section>
      <section className={balanceStyles.balance_table_section}>
        {loading ? (
          <Skeleton
            className={balanceStyles.skeleton}
            avatar
            active
            paragraph={{ rows: 4 }}
            loading={userBalance?.loading}
          />
        ) : (
          <table className={balanceStyles.balance_table}>
            <thead>
              <tr className={balanceStyles.balance_table_headers}>
                <th className={balanceStyles.currency}>Currency</th>
                <th>Available Balance</th>
                <th>Ledger Balance</th>
                <th></th>
              </tr>
            </thead>
            {userBalance?.balances && userBalance?.balances.length > 0 ? (
              <tbody>
                {userBalance?.balances?.map((balance, i) => (
                  <BalanceRow balance={balance} key={i} />
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <span>No data</span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </table>
        )}
        <div className={balanceStyles.bottom_rectangle}></div>
      </section>
    </>
  );
};

const BalanceAmount = ({ balance, currency = 'HKD' }: { balance: number; currency?: string }) => {
  const numPartsArray = toLocaleFixed(balance, 2).split('.');
  const realPart = numPartsArray[0],
    fractionPart = numPartsArray[1];
  return (
    <>
      <span>
        <span className={balanceStyles.currencySymbol}>{currency}</span>
        <span className={balanceStyles.wholePart}>{realPart}</span>
        <span className={balanceStyles.fractionPart}>.{fractionPart}</span>
      </span>
    </>
  );
};

export default React.memo(Balance);
