import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import AppLayout from 'src/components/Layout';
import Home from '../modules/home/pages/home';
import Payee from 'src/modules/payee/pages/payee';
import FAQ from 'src/modules/FAQ/pages/FAQ';
import BalancePage from 'src/modules/home/pages/balance';
import Transaction from 'src/components/Transaction';

export const PrivatePaths = {
  JPY: '/balances/JPY',
  BALANCES: '/balances',
  PAYEES: '/payees',
  PROFILE: '/profile',
  NOTIFICATION: '/notification',
  TRANSACTION: '/transaction',
  HOME: '/',
  FAQ: '/faq',
};

const privateRoutes = [
  {
    path: PrivatePaths.TRANSACTION,
    exact: true,
    component: Transaction,
  },
  {
    path: PrivatePaths.JPY,
    exact: true,
    component: () => <div>JPY</div>,
  },
  {
    path: PrivatePaths.BALANCES,
    exact: true,
    component: BalancePage,
  },
  {
    path: PrivatePaths.PAYEES,
    exact: true,
    component: Payee,
  },
  {
    path: PrivatePaths.FAQ,
    exact: true,
    component: FAQ,
  },
  {
    path: PrivatePaths.PROFILE,
    exact: true,
    component: () => <div>profile</div>,
  },
  {
    path: PrivatePaths.NOTIFICATION,
    exact: true,
    component: () => <div>notification</div>,
  },
  { path: PrivatePaths.HOME, exact: true, component: Home },
];

const DashboardRoutes = () => {
  return (
    <AppLayout>
      <Switch>
        {privateRoutes.map((route, index) => (
          <PrivateRoute
            key={index}
            component={route.component}
            path={route.path}
            exact={route.exact}
          />
        ))}
      </Switch>
    </AppLayout>
  );
};

export default DashboardRoutes;
