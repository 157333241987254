import { Button } from 'antd';
import { ReactComponent as PlusIcon } from 'src/assets/svg/plus.svg';

import styles from './index.module.css';

interface IProps {
  disable?: boolean;
  classname?: string;
  onClick: () => void;
}

const defaultProps = {
  classname: '',
  disable: false,
  onClick: () => {},
};

const CancelIcon = ({ onClick, classname, disable }: IProps & typeof defaultProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disable}
      type="ghost"
      className={`${styles.arrowBody} ${classname}`}
      icon={
        <span className={styles.icon}>
          <PlusIcon />
        </span>
      }
    />
  );
};

CancelIcon.defaultProps = defaultProps;

export default CancelIcon;
