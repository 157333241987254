import request from 'src/utils/request';

const getTransactionOtp = async () => {
  const response = await request.get('user/otp');
  if (response.data.error) throw response.data.error;
  return response.data;
};

const actions = {
  getTransactionOtp,
};

export default actions;
