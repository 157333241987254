import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import {
  IFetchPayeeInitiatedAction,
  IFetchPayeeSucceededAction,
  IFetchPayeeFailedAction,
  IPayee,
  IDeletePayeeInitiatedAction,
  IDeletePayeeSucceededAction,
  IDeletePayeeFailedAction,
  IrequestDeletePayee,
  IAddPayeeFailedAction,
  IAddPayeeInitiatedAction,
  IAddPayeeSucceededAction,
} from './types';

export const fetchPayeeInitiated: ActionCreator<IFetchPayeeInitiatedAction> = () => ({
  type: TYPES.FETCH_PAYEE_INITIATED,
});

export const fetchPayeeSucceded: ActionCreator<IFetchPayeeSucceededAction> = (
  payload: IPayee[],
) => ({
  type: TYPES.FETCH_PAYEE_SUCCEEDED,
  payload,
});

export const fetchPayeeFailed: ActionCreator<IFetchPayeeFailedAction> = () => ({
  type: TYPES.FETCH_PAYEE_FAILED,
});

export const deletePayeeInitiated: ActionCreator<IDeletePayeeInitiatedAction> = (id: string) => ({
  type: TYPES.DELETE_PAYEE_INITIATED,
  payload: id,
});

export const deletePayeeSucceded: ActionCreator<IDeletePayeeSucceededAction> = (
  payload: IrequestDeletePayee,
) => ({
  type: TYPES.DELETE_PAYEE_SUCCEEDED,
  payload,
});

export const deletePayeeFailed: ActionCreator<IDeletePayeeFailedAction> = (data: unknown) => ({
  type: TYPES.DELETE_PAYEE_FAILED,
  payload: data,
});

export const addPayeeInitiated: ActionCreator<IAddPayeeInitiatedAction> = (payload) => ({
  type: TYPES.ADD_PAYEE_INITIATED,
  payload,
});

export const addPayeeSuccessful: ActionCreator<IAddPayeeSucceededAction> = (payload: IPayee[]) => ({
  type: TYPES.ADD_PAYEE_SUCCESSFUL,
  payload,
});

export const addPayeeFailed: ActionCreator<IAddPayeeFailedAction> = (payload: object) => ({
  type: TYPES.ADD_PAYEE_FAILED,
  payload,
});

export const clearState = () => ({
  type: TYPES.CLEAR_PAYEE_STATE,
});
