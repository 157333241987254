import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import {
  IFetchBalancesInitiatedAction,
  IFetchBalancesSucceededAction,
  IFetchBalancesFailedAction,
  IBalance,
} from './types';

export const fetchBalancesInitiated: ActionCreator<IFetchBalancesInitiatedAction> = () => ({
  type: TYPES.FETCH_BALANCES_INITIATED,
  payload: null,
});

export const fetchBalancesSucceeded: ActionCreator<IFetchBalancesSucceededAction> = (
  balances: IBalance[],
) => ({
  type: TYPES.FETCH_BALANCES_SUCCEEDED,
  payload: balances,
});

export const fetchBalancesFailed: ActionCreator<IFetchBalancesFailedAction> = () => ({
  type: TYPES.FETCH_BALANCES_FAILED,
  payload: null,
});
