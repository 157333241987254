import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const CardHeader = ({
  id,
  name,
  nickname,
  handleDeletePayee,
  setLoading,
}: {
  id: string;
  name: string;
  nickname?: string;
  handleDeletePayee: (id: string) => void;
  setLoading: (value: boolean) => void;
}) => {
  return (
    <div>
      <span className="payeeCard__head__text">{name}</span>{' '}
      {nickname && (
        <span className="payeeCard__head__text payeeCard__head-subText">({nickname})</span>
      )}
      <CloseOutlined
        className="payeeCard__head-icon payeeCard__head-visibility payeeCard__head-icon-alignment"
        onClick={() => {
          setLoading(true);
          handleDeletePayee(id);
        }}
      />
    </div>
  );
};

export default CardHeader;
