import { call, put, takeLatest } from 'redux-saga/effects';
import { IApplicationState } from '../../../common/interface';
import { fetchQuotesFailed, fetchQuotesSucceeded } from './actions';
import { FETCH_QUOTES_FAILED, FETCH_QUOTES_INITIATED, FETCH_QUOTES_SUCCEEDED } from './actionTypes';
import { getQuotes } from './requests';
import { IHomeState, IFetchQuotesInitiatedAction, IQuote, IHomeActions } from './types';

export const INITIAL_STATE: IHomeState = {
  loading: false,
  quotes: [],
  balances: [],
};

export function* getQuotesRequest(action: IFetchQuotesInitiatedAction) {
  try {
    const quotes: IQuote[] = yield call(getQuotes);

    yield put(fetchQuotesSucceeded(quotes));
  } catch (e) {
    yield put(fetchQuotesFailed());
  }
}

export function* GetHome() {
  yield takeLatest(FETCH_QUOTES_INITIATED, getQuotesRequest);
}

export const selectHome = (state: IApplicationState) => state.home;
export const selectQuotes = (state: IApplicationState) => state.home.quotes;

export default function reducer(state = INITIAL_STATE, action: IHomeActions) {
  switch (action.type) {
    case FETCH_QUOTES_INITIATED:
      return { ...state, loading: true };
    case FETCH_QUOTES_SUCCEEDED:
      return { ...state, loading: false, quotes: action.quotes };
    case FETCH_QUOTES_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
