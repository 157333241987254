import { Rule } from 'antd/lib/form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const inviteCode = '80808';

type TName =
  | 'company_name'
  | 'email'
  | 'invite_code'
  | 'password'
  | 'lastUpdatedForm'
  | 'phone_number'
  | 'confirmPassword';

interface IFormfields {
  name: TName;
  type: string;
  label: string;
  placeholder: string;
  dependencies?: string[];
  rules?: Rule &
    {
      required?: boolean;
      message?: string;
      name?: string;
      validator?: (_: unknown, value: string) => Promise<undefined>;
    }[];
}
export interface IFormItem {
  action?: string;
  title?: string;
  subTitle?: string;
  formDescription?: string;
  showBackArrow?: boolean;
  fields?: IFormfields[];
}
const passwordValidations = [
  {
    name: 'at least 10 characters',
    regExp: '.{10,}',
  },
  {
    name: 'at least 1 number',
    regExp: '.*[0-9].*',
  },
  {
    name: 'at least 1 punctuation character',
    regExp: '.*[^A-Za-z0-9].*',
  },
  {
    name: 'at least 1 uppercase character',
    regExp: '.*[A-Z].*',
  },
  {
    name: 'at least 1 lowercase character',
    regExp: '.*[a-z].*',
  },
];

const registrationFormItems: IFormItem[] = [
  {
    title: 'Welcome',
    subTitle: 'Get started by creating your account',
    formDescription: 'Please enter the required information below',
    showBackArrow: true,
    fields: [
      {
        name: 'company_name',
        type: 'text',
        label: 'Company Name',
        placeholder: 'your company name',
        rules: [{ required: true, message: '' }],
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        placeholder: 'example@mail.com',
        rules: [{ required: true, message: '' }],
      },
      {
        name: 'invite_code',
        type: 'number',
        label: 'Invite code',
        placeholder: '00000',
        rules: [{ required: true, message: '' }],
      },
      {
        name: 'phone_number',
        type: '',
        label: '',
        placeholder: 'Phone Number',
        rules: [
          {
            validator: async (_: unknown, input: string = '') => {
              if (!isPossiblePhoneNumber(input)) {
                return Promise.reject();
              }
            },
          },
        ],
      },
    ],
  },
  {
    fields: [
      {
        name: 'password',
        type: 'password',
        label: 'Password',
        placeholder: 'password',
        rules: [
          ...passwordValidations.map(({ name, regExp }) => ({
            name,
            validator: async (_: unknown, input: string) => {
              const regex = new RegExp(regExp);
              if (!regex.test(input)) {
                return Promise.reject();
              }
            },
          })),
        ],
      },
      {
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm password',
        placeholder: 'confirm password',
        dependencies: ['password'],
        rules: [
          { required: true, message: '' },
          ({ getFieldValue }: { getFieldValue: (field: string) => string }) => ({
            validator(_: unknown, value: string) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
          }),
        ],
      },
    ],
    title: 'Reset Password',
    subTitle: 'Strongly secure your account',
    formDescription: 'Please create your password',
    showBackArrow: true,
  },
];

export default registrationFormItems;
