import { useState, useEffect } from 'react';
import { IBalance } from 'src/store/modules/balance/types';
import getCurrencyName from 'src/utils/currency';
import balanceStyles from './Balance.module.css';
import { RightOutlined } from '@ant-design/icons';

const BalanceRow = ({ balance }: { balance: IBalance }) => {
  const [image, setImage] = useState('');
  const [loadImages, setLoadImages] = useState(true);

  useEffect(() => {
    if (loadImages) {
      loadImage(balance.currency);
    }
    return () => setLoadImages(false);
  }, [balance.currency, loadImages, setLoadImages]);

  const loadImage = async (imageName: string) => {
    const importedImage = await import(`../../../../assets/flagIcon/${imageName}.png`);
    setImage(importedImage.default);
  };

  return (
    <tr key={balance.uid}>
      <td>
        <div className={balanceStyles.imageWrapperSmall}>
          <img src={image} alt={balance.currency} />
        </div>
        <div className={balanceStyles.currency}>
          <div className={balanceStyles.imageWrapper}>
            <img src={image} alt={balance.currency} />
          </div>
          <p className={balanceStyles.currency_symbol}>{balance.currency}</p>
          <div className={balanceStyles.currency_name}>
            <small>{getCurrencyName(balance.currency)}</small>
          </div>
        </div>
      </td>
      <td className={balanceStyles.available}>
        <p>
          {Number(balance.available).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          <br className={balanceStyles.break}></br>
          {balance.currency}
        </p>
      </td>
      <td className={balanceStyles.ledger}>
        <p>
          {Number(balance.ledger).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          <br className={balanceStyles.break}></br>
          {balance.currency}
        </p>
      </td>
      <td className={balanceStyles.ledger}>
        <RightOutlined className={balanceStyles.ledgerIcon} />
      </td>
    </tr>
  );
};

export default BalanceRow;
