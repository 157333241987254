import CustomBtn from 'src/components/Button';
import { Link } from 'react-router-dom';
import styles from './login.module.css';
import { Form } from 'antd';
import { DefaultFormInput } from 'src/components/CustomInputs';
import { useCallback, useState } from 'react';
import { useHandleFormChange } from 'src/modules/theia/auth/component/modules/signup/form';

interface IProps {
  isVisible: boolean;
}
interface IFormError {
  [index: string]: string[];
}
type TFormError = IFormError | null;
interface ILoginForm {
  email?: string;
  password?: string;
}

const Login = (props: IProps) => {
  const [, setLoginForm] = useState<ILoginForm>({});
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();

  const handleFormChange = useHandleFormChange(form, setFormErrors);

  const handleFinish = useCallback((values: { [index: string]: unknown }) => {
    setLoginForm((prevValues) => ({ ...prevValues, ...values }));
  }, []);

  return (
    <div data-testid="theia-login" className={styles.wrapper}>
      {props.isVisible ? (
        <Form
          role="form"
          onFinish={handleFinish}
          form={form}
          className={styles.container}
          onFieldsChange={handleFormChange}
        >
          <div className={styles.innerwrap}>
            <div className={`caption_ ${styles.caption}`}>
              <h1>Hello</h1>
            </div>
            <h3 className={styles.subcaption}>Please login to your account</h3>
            <div className={styles.inputBox}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'please input email',
                  },
                ]}
              >
                <DefaultFormInput
                  label="username or email"
                  labelClassName={styles.label}
                  type="email"
                  placeholder="username@email.com"
                  data-testid="login_email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'please input password',
                  },
                ]}
              >
                <DefaultFormInput
                  label="password"
                  labelClassName={styles.label}
                  type="password"
                  placeholder="*********"
                  data-testid="login_pwd"
                />
              </Form.Item>
              <Form.Item className={styles.formbtn}>
                <CustomBtn disabled={!!formError} htmlType="submit" className={styles.submitBtn}>
                  Login
                </CustomBtn>
              </Form.Item>
            </div>
          </div>

          <p className={styles.signupLink}>
            Don't have an account? <Link to="/theia-signup">Signup</Link>
          </p>
        </Form>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Login;
