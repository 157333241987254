import imageCarouselStyles from './ImageCarousel.module.css';
import { useState, useEffect, useCallback } from 'react';
import { Image } from 'antd';

const defaultProps: ImageCarouselProps = {
  carouselItem: [],
  className: '',
  interval: 5,
};

type TColor = 'blue' | 'gold' | 'brown';

interface IDataItem {
  heading?: string;
  title?: string;
  subTitle?: string;
  image: string;
  color?: TColor;
}

interface ImageCarouselProps {
  carouselItem: IDataItem[];
  className?: string;
  /**Interval in seconds */
  interval?: number;
}

const colors = ['blue', 'gold', 'brown'];

const ImageCarousel = ({ carouselItem, className, interval }: ImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    carouselItem.map((item, idx, items) => {
      if (item.color) return item;
      let nextColorIdx = idx % colors.length;
      if (colors[nextColorIdx] === items[idx === 0 ? 0 : idx - 1].color) {
        nextColorIdx = (nextColorIdx + 1) % colors.length;
      }
      item.color = colors[nextColorIdx] as TColor;
      return item;
    });
  }, [carouselItem]);

  useEffect(() => {
    let timer1 = setInterval(() => {
      if (carouselItem.length > 1) {
        setCurrentIndex((prevIdx) => (prevIdx >= carouselItem.length - 1 ? 0 : prevIdx + 1));
      }
    }, interval! * 1000);

    return () => {
      clearInterval(timer1);
    };
  }, [interval, carouselItem]);

  const setCarouselTheme = useCallback((color: string, type: string) => {
    switch (type) {
      case 'container':
        if (color === 'gold') {
          return `${imageCarouselStyles.carouselContainerGold}`;
        } else if (color === 'blue') {
          return `${imageCarouselStyles.carouselContainerBlue}`;
        }
        return `${imageCarouselStyles.carouselContainerBrown}`;
      case 'span':
        if (color === 'gold') {
          return `${imageCarouselStyles.spanDefaultGold}`;
        } else if (color === 'blue') {
          return `${imageCarouselStyles.spanDefaultBlue}`;
        }
        return `${imageCarouselStyles.spanDefaultBrown}`;
      case 'text':
        if (color === 'gold') {
          return `${imageCarouselStyles.pGold}`;
        } else if (color === 'blue') {
          return `${imageCarouselStyles.pBlue}`;
        }
        return `${imageCarouselStyles.pBrown}`;

      default:
        break;
    }
  }, []);

  return (
    <div
      data-testid="img-carousel"
      className={`${className} ${imageCarouselStyles.carouselContainer} ${setCarouselTheme(
        carouselItem[currentIndex]?.color || colors[0],
        'container',
      )} `}
    >
      <div>
        {carouselItem.map((item, index) => (
          <div key={index}>
            {currentIndex === index && (
              <div
                className={`${imageCarouselStyles.carouselItem}`}
                data-testid={`carousel-img-${index}`}
              >
                <p
                  className={`${imageCarouselStyles.heading} ${setCarouselTheme(
                    item.color!,
                    'text',
                  )}`}
                >
                  {item?.heading}
                </p>
                <p
                  className={`${imageCarouselStyles.title} ${setCarouselTheme(
                    item.color!,
                    'text',
                  )}`}
                >
                  {item?.title}
                </p>
                <p
                  className={`${imageCarouselStyles.subTitle} ${setCarouselTheme(
                    item.color!,
                    'text',
                  )}`}
                >
                  {item?.subTitle}
                </p>
                <Image
                  preview={false}
                  src={item.image}
                  className={`${imageCarouselStyles.img} ${imageCarouselStyles.fade}`}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={`${imageCarouselStyles.spanWrapper}`}>
        {carouselItem.map((item, index) => (
          <span
            onClick={() => setCurrentIndex(index)}
            key={index}
            data-testid={`carousel-dot-${index}`}
            className={`${imageCarouselStyles.spanDefault} ${
              currentIndex === index && imageCarouselStyles[item.color!]
            } ${setCarouselTheme(item.color!, 'span')}`}
          />
        ))}
      </div>
    </div>
  );
};

ImageCarousel.defaultProps = defaultProps;

export default ImageCarousel;
