import { combineReducers } from 'redux';
import auth from './modules/auth';
import home from './modules/home';
import transaction from './modules/transaction';
import balance from './modules/balance';
import payee from './modules/payee';
import rate from './modules/rate';

import { IApplicationState } from '../common/interface';

export default combineReducers<IApplicationState>({
  auth,
  home,
  transaction,
  balance,
  payee,
  rate,
});
