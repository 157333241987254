import Layout from './Layout';

interface IProps {
  children?: React.ReactNode;
}

const AppLayout = (props: IProps) => {
  return <Layout>{props.children}</Layout>;
};

export default AppLayout;
