import { useCallback, Dispatch, SetStateAction } from 'react';
import { IFormItem } from 'src/modules/auth/components/SignupModal/form';
import { Rule } from 'antd/lib/form';
import { FormInstance } from 'antd';
import { TFormError } from 'src/modules/auth/components/ForgetPasswordModal/types';
import { IProps } from 'src/modules/theia/util/types';

export const inviteCode = '80808';

type TName = 'company_name' | 'email' | 'invite_code' | 'phone_number';

interface IFormfields {
  name: TName;
  type: string;
  label: string;
  placeholder: string;
  dependencies?: string[];
  rules?: Rule &
    {
      required?: boolean;
      message?: string;
      name?: string;
      validator?: (_: unknown, value: string) => Promise<undefined>;
    }[];
}
export interface IRegFormItem {
  action?: string;
  title?: string;
  subTitle?: string;
  formDescription?: string;
  showBackArrow?: boolean;
  fields?: IFormfields[];
}
const passwordValidations = [
  {
    name: 'at least 10 characters',
    regExp: '.{10,}',
  },
  {
    name: 'at least 1 number',
    regExp: '.*[0-9].*',
  },
  {
    name: 'at least 1 punctuation character',
    regExp: '.*[^A-Za-z0-9].*',
  },
  {
    name: 'at least 1 uppercase character',
    regExp: '.*[A-Z].*',
  },
  {
    name: 'at least 1 lowercase character',
    regExp: '.*[a-z].*',
  },
];

const setPasswordFormItems: IFormItem[] = [
  {
    title: 'Welcome',
    subTitle: 'Get started by creating your account',
    formDescription: 'Please enter the required information below',
    showBackArrow: true,
  },
  {
    fields: [
      {
        name: 'password',
        type: 'password',
        label: 'choose a password',
        placeholder: 'password',
        rules: [
          ...passwordValidations.map(({ name, regExp }) => ({
            name,
            validator: async (_: unknown, input: string) => {
              const regex = new RegExp(regExp);
              if (!regex.test(input)) {
                return Promise.reject();
              }
            },
          })),
        ],
      },
      {
        name: 'confirmPassword',
        type: 'password',
        label: 'confirm password',
        placeholder: 'confirm password',
        dependencies: ['password'],
        rules: [
          { required: true, message: '' },
          ({ getFieldValue }: { getFieldValue: (field: string) => string }) => ({
            validator(_: unknown, value: string) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject();
            },
          }),
        ],
      },
    ],
  },
];

export const RegFormItem: IRegFormItem = {
  title: 'Get started by creating your account',
  formDescription: 'Please enter the required information below',
  showBackArrow: true,
  fields: [
    {
      name: 'company_name',
      type: 'text',
      label: 'Company Name',
      placeholder: 'your company name',
      rules: [
        {
          required: true,
          message: 'Input too short',
          validator: async (_: unknown, input: string) => {
            if (input.length < 2) return Promise.reject('Input too short');
          },
        },
      ],
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email address',
      placeholder: 'example@mail.com',
      rules: [
        {
          required: true,
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
      ],
    },
    {
      name: 'phone_number',
      type: '',
      label: 'phone number',
      placeholder: '',
      rules: [
        {
          required: true,
          message: 'Invalid phone number',
          validator: async (_: unknown, input: string) => {
            if (!/^\+{0,1}\d+$/.test(input)) {
              return Promise.reject('Invalid phone number');
            }
          },
        },
      ],
    },
    {
      name: 'invite_code',
      type: 'number',
      label: 'Invite code',
      placeholder: '00000',
      rules: [
        {
          required: true,
          message: '',
          validator: async (_: unknown, input: string) => {
            if (input.length < 2) return Promise.reject();
          },
        },
        {
          required: true,
          message: '',
          validator: async (_: unknown, input: string) => {
            if (input.length > 5) return Promise.reject();
          },
        },
      ],
    },
  ],
};

export const useHandleFormChange = (
  form: FormInstance<any>,
  setFormErrors: Dispatch<SetStateAction<TFormError>>,
  setPassword?: Dispatch<SetStateAction<{ password?: string }>>,
) => {
  const formChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object.keys(errors).length ? errors : null);
    setPassword && setPassword({ password: form.getFieldValue('password') });
  }, [form, setFormErrors, setPassword]);
  return formChange;
};

export const useHandleFinish = <T>(setFormItem: Dispatch<SetStateAction<T>>, props: IProps) => {
  const handleFinish = useCallback(
    (values: { [index: string]: unknown } = {}) => {
      setFormItem((prevValues: any) => ({ ...prevValues, ...values }));
      props.onNext();
    },
    [setFormItem, props],
  );

  return handleFinish;
};

export default setPasswordFormItems;
