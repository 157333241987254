import request from '../../../utils/request';

export const getQuotes = async () => {
  const response = await request.get('/user/query/rate');

  if (response.status === 200 && response.data.success) {
    return response.data.result;
  }
  return [];
};
