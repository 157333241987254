import React from 'react';
import FormBackArrow from 'src/components/FormBackArrow';
import Stepper from '../Stepper';
import styles from './index.module.css';

interface IProps {
  title?: string;
  subTitle?: string;
  showHeader: boolean;
  formDescription?: string;
  children?: React.ReactNode;
  onBackarrowClick?: () => void;
  steps: { title: string }[];
  currentStep: number;
}

const defaultProps = {
  title: '',
  subTitle: '',
  formDescription: '',
  formBodyClass: '',
  showHeader: false,
  showBackArrow: true,
  showStepper: false,
  onBackarrowClick: () => {},
  steps: [{}],
  currentStep: 0,
};

const AuthForm = (props: IProps & typeof defaultProps) => {
  return (
    <div>
      {props.showHeader && (
        <div className={styles.header}>
          <div>
            {props.showBackArrow && (
              <FormBackArrow onClick={props.onBackarrowClick} classname={styles.backArrow} />
            )}
          </div>
          <div className={styles.stepperContainer}>
            {props.showStepper && <Stepper steps={props.steps} current={props.currentStep} />}
          </div>
        </div>
      )}
      <div className={props.formBodyClass ? props.formBodyClass : styles.formBodyClass}>
        {props.title || props.subTitle ? (
          <section>
            <p className={styles.title}>{props.title}</p>
            <p className={styles.subTitle}>{props.subTitle}</p>
          </section>
        ) : null}
        <section className="childrenContainer">
          <div className={styles.child}>
            <p className={styles.formDescription}>{props.formDescription}</p>
            {props.children}
          </div>
        </section>
      </div>
    </div>
  );
};

AuthForm.defaultProps = defaultProps;

export default AuthForm;
