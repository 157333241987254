import { Input as AntInput } from 'antd';
import { InputProps, PasswordProps } from 'antd/lib/input';

import './index.css';

interface IProps {
  error: boolean;
  label?: string;
  type?: typeof AntInput.defaultProps.type;
  labelClassName?: string;
}

const Input = ({ error, labelClassName, ...props }: IProps & InputProps & PasswordProps) => {
  return (
    <div className="defaultFormInput">
      <span
        className={`defaultFormInput-label ${error ? 'defaultFormInput-label--error' : ''}${
          labelClassName ? ` ${labelClassName}` : ''
        }`}
      >
        {props.label}
      </span>
      {props.type === 'password' ? (
        <AntInput.Password className="defaultFormInput-password" {...props} />
      ) : (
        <AntInput className="defaultFormInput-input" {...props} />
      )}
    </div>
  );
};

Input.defaultProps = {
  error: false,
  label: 'Label',
  ...AntInput.defaultProps,
};

export default Input;
