import React, { useState, useEffect } from 'react';
import { Card, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import homeStyles from './Home.module.css';
import { fetchQuotesInitiated } from '../../../../store/modules/home/actions';
import { fetchBalancesInitiated } from '../../../../store/modules/balance/actions';
import { IQuote } from '../../../../store/modules/home/types';
import { selectHome } from '../../../../store/modules/home';
import { selectBalance } from '../../../../store/modules/balance';
import Paginator from '../../../../components/Paginator';
import QuoteRow from './QuoteRow';

const Home = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [itemsPerPage] = useState(30);

  const dispatch = useDispatch();
  const { quotes, loading } = useSelector(selectHome);
  const { balances } = useSelector(selectBalance);
  const [sortedQuotes, setSortedQuotes] = useState<IQuote[] | undefined>([]);

  useEffect(() => {
    dispatch(fetchQuotesInitiated());
    dispatch(fetchBalancesInitiated());
  }, [dispatch]);

  useEffect(() => {
    if (quotes) {
      const pageCount =
        quotes.length % itemsPerPage
          ? Math.floor(quotes.length / itemsPerPage) + 1
          : Math.floor(quotes.length / itemsPerPage);

      setPages(pageCount);
      const sortOrderCurrencies: string[] | undefined = balances
        ?.sort((a, b) => +new Date(b.last_updated) - +new Date(a.last_updated))
        .map((balance) => balance.currency);

      if (sortOrderCurrencies) {
        const unusedCurrencies = quotes
          .filter((q) => !sortOrderCurrencies.includes(q.currency))
          .map((q) => q.currency);
        sortOrderCurrencies.push(...unusedCurrencies);
        quotes?.sort(function (a, b) {
          return sortOrderCurrencies.indexOf(a.currency) - sortOrderCurrencies.indexOf(b.currency);
        });
      }
      setSortedQuotes(quotes);
    } else {
      setPages(1);
    }
  }, [quotes, itemsPerPage, balances]);

  return (
    <>
      <Card
        style={{
          marginBottom: '30px',
        }}
        className={homeStyles.content_card_header}
      />
      <section className={homeStyles.insights}>
        <div className={homeStyles.insights_head}>
          <h1>Exchange Rate</h1>
        </div>

        <Skeleton
          className={homeStyles.skeleton}
          avatar
          active
          paragraph={{ rows: 10 }}
          loading={loading}
        >
          <table className={homeStyles.exchange_table}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th className={homeStyles.stretch}>Buy</th>
                <th className={homeStyles.stretch}>Sell</th>
              </tr>
            </thead>
            <tbody>
              {sortedQuotes
                ?.slice(itemsPerPage * (page - 1), itemsPerPage * page)
                .map((quote, i) => (
                  <QuoteRow quote={quote} key={i} />
                ))}
            </tbody>
          </table>
        </Skeleton>
        <Paginator
          setPage={setPage}
          page={page}
          pages={pages}
          itemsPerPage={itemsPerPage}
          items={quotes}
        />
      </section>
    </>
  );
};

export default React.memo(Home);
