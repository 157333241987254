import { memo, useMemo, useState } from 'react';
import Search from '../../../../components/Search';
import styles from './faq.module.css';
import { Col, Row } from 'antd';
import Button from '../../../../components/Button';
import { Accordion } from './Accordion';
import { SettingOutlined } from '@ant-design/icons';
import { useWidth } from 'src/hooks/useWidth';

const text = (
  <p style={{ paddingLeft: 24 }}>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet purus vel neque viverra
    pharetra. Fusce viverra, metus ac sagittis bibendum, est nisi facilisis purus, ut vestibulum
    velit odio a felis. Morbi finibus sagittis arcu, sit amet mattis mauris lobortis faucibus.
    Phasellus rhoncus, ante non porta efficitur, orci sem gravida tellus, vitae lobortis neque lorem
    id ex.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet purus vel neque
    viverra pharetra. Fusce viverra, metus ac sagittis bibendum, est nisi facilisis purus, ut
    vestibulum velit odio a felis. Morbi finibus sagittis arcu, sit amet mattis mauris lobortis
    faucibus. Phasellus rhoncus, ante non porta efficitur, orci sem gravida tellus, vitae lobortis
    neque lorem id ex.
  </p>
);

const FAQ = () => {
  const [search, setSearch] = useState('');
  const { width } = useWidth();
  const items = useMemo(() => {
    const data = [
      {
        title: 'Transaction Issue',
        icon: <SettingOutlined />,
        accordionValues: [
          {
            header: 'How long does it take?',
            value: text,
          },
          {
            header: 'Which currencies are supported?',
            value: text,
          },
          {
            header: 'How to withdraw my money?',
            value: text,
          },
        ],
      },
      {
        title: 'Card issue',
        icon: <SettingOutlined />,
        accordionValues: [
          {
            header: 'Which currencies are supported?',
            value: text,
          },
          {
            header: 'How to withdraw my money?',
            value: text,
          },
        ],
      },
    ];
    if (search) {
      return data.filter((d) => d.title.toLowerCase().includes(search.toLowerCase()));
    }
    return data;
  }, [search]);
  return (
    <>
      <section className={styles.faq_container}>
        <Row>
          <Col span={width >= 768 ? 18 : 22} offset={width >= 768 ? 3 : 1}>
            {width >= 768 && (
              <Row>
                <Col span={12}>
                  <Search onSearch={(value) => setSearch(value)} />
                </Col>
                <Col span={12}>
                  <Button type={'secondary'} className={styles.faq_btn}>
                    Contact support
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={24}>
                <Accordion items={items} />
              </Col>
              {width < 768 && (
                <Col span={24} className={styles.support_btn_container}>
                  <Button type={'primary'} className={styles.support_btn}>
                    submit a message to our support
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default memo(FAQ);
