import { Layout, PageHeader } from 'antd';
import 'antd/dist/antd.css';
import styles from './index.module.css';
import { useAuthentication } from '../../../hooks/useAuth';

interface IProps {
  Sider: React.ReactNode;
  Content: React.ReactNode;
}

const DesktopLayoutStructure = (props: IProps) => {
  const { user } = useAuthentication();
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <PageHeader className={styles.top_bar_header}>
          <h1>ReadyFX</h1>
          <p>{user && user['custom:company_name']}</p>
        </PageHeader>
        <Layout>
          {props.Sider}
          {props.Content}
        </Layout>
      </Layout>
    </>
  );
};

export default DesktopLayoutStructure;
