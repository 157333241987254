import { ObjectHTMLAttributes } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const RenderHtml = (props: ObjectHTMLAttributes<HTMLObjectElement>) => {
  return (
    <object {...props} type="text/html" data={props.data}>
      <LoadingOutlined />
    </object>
  );
};

export default RenderHtml;
