import { Layout } from 'antd';
import 'antd/dist/antd.css';
import styles from './index.module.css';

const { Content } = Layout;

interface IProps {
  children?: React.ReactNode;
}

const FXContent = (props: IProps) => {
  return (
    <Layout className={styles.site_layout}>
      <Content className={styles.content_layout}>
        <div style={{ textAlign: 'center', height: '100%' }}>{props.children}</div>
      </Content>
    </Layout>
  );
};

export default FXContent;
