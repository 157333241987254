import { useCallback, useEffect, useState } from 'react';
import PasswordChecker from './CheckerItem';

type TValidator = (_: any, names: any) => Promise<undefined>;
type TErrorState = 'valid' | 'invalid';
interface IValidations {
  validator: TValidator;
  name: string;
}
interface IValidationResult {
  errorState: TErrorState;
  name: string;
}
interface IProps {
  /**
   * Get error state
   */
  password: string;
  /**
   * - Rules used by password antd field
   * - The name passed in the rule object will be used
   * to name each validator found
   * - Only rules with validator function are considered
   */
  rules: {
    name: string;
    validator: TValidator;
  }[];
}

const defaultProps = {
  password: '',
};

const PasswordStrength = ({ rules, password }: IProps & typeof defaultProps) => {
  const [validationResults, setValidationResults] = useState<IValidationResult[]>([]);

  const validate = async (validator: TValidator, field: string): Promise<TErrorState> => {
    try {
      await validator('', field);
      return 'valid';
    } catch (err) {
      return 'invalid';
    }
  };

  const handleValidation = useCallback(async (validations: IValidations[], password: string) => {
    const results: IValidationResult[] = [];

    for (let i = 0; i < validations.length; i++) {
      const { validator, name } = validations[i];
      const response = await validate(validator, password);
      results.push({
        name,
        errorState: response,
      });
    }
    setValidationResults(results);
  }, []);

  useEffect(() => {
    handleValidation(rules, password);
  }, [handleValidation, rules, password]);

  return (
    <div>
      {validationResults.map(({ errorState, name }) => (
        <PasswordChecker key={`passwordChecker-${name}`} errorState={errorState} text={name} />
      ))}
    </div>
  );
};

PasswordStrength.defaultProps = defaultProps;

export default PasswordStrength;
