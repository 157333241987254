import { useState, useEffect } from 'react';
import BackArrow from '../../BackArrow';
import CustomBtn from 'src/components/Button';
import { IFormItem } from 'src/modules/auth/components/SignupModal/form';
import { TFormError } from 'src/modules/auth/components/ForgetPasswordModal/types';
import { IValidations } from 'src/modules/auth/components/ForgetPasswordModal/types';
import { Form } from 'antd';
import PasswordStrength from 'src/modules/auth/components/PasswordStrength';
import { DefaultFormInput } from 'src/components/CustomInputs';
import styles from './SetPassword.module.css';
import registrationFormItems from './form';
import { IProps } from 'src/modules/theia/util/types';
import {
  useHandleFormChange,
  useHandleFinish,
} from 'src/modules/theia/auth/component/modules/signup/form';

interface ITheiaPasswordForm {
  password?: string;
}

const SetPassword = (props: IProps) => {
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [password, setPassword] = useState<ITheiaPasswordForm>({});
  const [validations, setValidations] = useState<IValidations[]>([]);
  const [form] = Form.useForm();
  const handleFormChange = useHandleFormChange(form, setFormErrors, setPassword);
  const handleFinish = useHandleFinish<ITheiaPasswordForm>(setPassword, props);
  const formItem: IFormItem = registrationFormItems[1];

  useEffect(() => {
    let availableValidations: IValidations[] = [];

    if (formItem.fields) {
      formItem?.fields?.[0]?.rules &&
        formItem.fields[0].rules.forEach(({ validator, name = '' }) => {
          validator && availableValidations.push({ validator, name });
        });

      setValidations(availableValidations);
    }
  }, [formItem, formItem.fields]);

  return (
    <div className={`${styles.setPasswordContainer}`}>
      <BackArrow onClick={props.onBack} />
      <p className={`${styles.headerText}`}>
        Choose a strong
        <br /> password
      </p>
      <div className={`${styles.setPasswordWrapper}`}>
        <Form
          onFinish={(values) => handleFinish({ ...values })}
          form={form}
          onFieldsChange={handleFormChange}
        >
          {(formItem.fields || []).map((item) => (
            <Form.Item
              {...(item.dependencies ? { dependencies: item.dependencies } : {})}
              key={`passwordForm-item-${item?.name}`}
              name={item?.name}
              rules={item?.rules}
              className={styles.formItem}
            >
              <DefaultFormInput
                error={formError ? !!formError[item.name] : false}
                label={item?.label}
                key={`passwordForm-input-${item?.name}`}
                type={item.type}
                placeholder={item?.placeholder}
              />
            </Form.Item>
          ))}
          <CustomBtn
            htmlType="submit"
            disabled={!!formError}
            className={styles.nextButton}
            logo={false}
            data-testid="button"
          >
            set password
          </CustomBtn>
          <div className={`${styles.passwordValidation}`}>
            <PasswordStrength password={password.password} rules={validations} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SetPassword;
