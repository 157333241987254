import React, { useCallback, useEffect, useState } from 'react';
import styles from 'src/modules/payee/components/Payee/Payee.module.css';
import { Modal, Form, Row, Col, FormInstance } from 'antd';
import { DefaultFormInput } from 'src/components/CustomInputs';
import CustomBtn from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addPayeeInitiated } from 'src/store/modules/payee/actions';
import leftArrow from 'src/assets/svg/leftArrow.svg';
import { selectPayee } from 'src/store/modules/payee';
import { selectAuth } from 'src/store/modules/auth';
import { IAuthState } from 'src/store/modules/auth/types';
import { openNotification, openError } from 'src/utils/common';
import { clearState } from 'src/store/modules/payee/actions';
import { IPayee } from 'src/store/modules/payee/types';
import NumberFormat from 'react-number-format';

interface IProps {
  isModalvisible: boolean;
  handleCancel: () => void;
  form: FormInstance;
}

export interface IUidProviderProps {
  userUid?: IAuthState;
  setuserUid: (userUid: IAuthState) => void;
}

const AddPayeeModal: React.FC<IProps> = ({ isModalvisible, handleCancel, form }) => {
  const { innerWidth: width } = window;
  const [userUid, setuserUid] = useState<IUidProviderProps | string>();
  const dispatch = useDispatch();
  const { loading: isLoading, successful: isAddPayeeSuccessful, error } = useSelector(selectPayee);
  const auth = useSelector(selectAuth);
  const isMobile = width <= 760;

  const clearErrSuccess = useCallback(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    if (isAddPayeeSuccessful) {
      openNotification('Payee added successfully');
      clearErrSuccess();
      handleCancel();
    }
    if (error != null) {
      openError(`Something un-expected occured, please try again. ${error}`);
      clearErrSuccess();
    }
    setuserUid(auth.user?.getUsername());
  }, [auth.user, clearErrSuccess, dispatch, error, handleCancel, isAddPayeeSuccessful]);

  const addPayeeCallback = useCallback(
    async (data) => {
      await dispatch(addPayeeInitiated(data));
    },
    [dispatch],
  );

  const handleOk = (form: FormInstance<any>) => {
    form
      .validateFields()
      .then((values: IPayee) => {
        const payeePayload = {
          ...values,
          uid: userUid,
        };
        addPayeeCallback(payeePayload);
      })
      .catch((info: unknown) => {
        console.error(info);
      });
  };

  const modalForm1 = (
    <>
      <Form.Item
        name="name"
        className={styles.payeemodal__inputbox}
        rules={[
          {
            required: true,
            message: 'Please input beneficiary name!',
          },
        ]}
      >
        <DefaultFormInput label="Beneficiary Name" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item name="id_number" className={styles.payeemodal__inputbox}>
        <DefaultFormInput label="ID number" type="number" placeholder="optional" />
      </Form.Item>
      <Form.Item
        name="email"
        className={styles.payeemodal__inputbox}
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: "Please input payee's E-mail!",
          },
        ]}
      >
        <DefaultFormInput label="E-mail" type="email" placeholder="example@mail.com" />
      </Form.Item>
      <Form.Item
        name="phone"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: 'Phone must be at least 9 characters!', min: 9 }]}
      >
        <NumberFormat
          customInput={DefaultFormInput}
          allowNegative={false}
          prefix="+"
          label="Phone number"
          placeholder="+852 0000-00"
          minLength={9}
        />
      </Form.Item>
      <Form.Item name="nickname" className={styles.payeemodal__inputbox}>
        <DefaultFormInput label="Nickname" type="text" placeholder="optional" />
      </Form.Item>
    </>
  );

  const modalForm2 = (
    <>
      <Form.Item
        name="bank_account"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: "Please input payee's account number!" }]}
      >
        <DefaultFormInput label="Account Number" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item
        name="bank_name"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: "Please input payee's bank name!" }]}
      >
        <DefaultFormInput label="Bank Name" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item
        name="branch_name"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: 'Please input branch name!' }]}
      >
        <DefaultFormInput label="Branch name" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item
        name="bank_address"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: 'Please input bank address!' }]}
      >
        <DefaultFormInput label="Bank Address" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item
        name="swift_code"
        className={styles.payeemodal__inputbox}
        rules={[{ required: true, message: "Please input payee's swift code" }]}
      >
        <DefaultFormInput label="Swift code" type="text" placeholder="Required" />
      </Form.Item>
      <Form.Item name="address" className={styles.payeemodal__inputbox}>
        <DefaultFormInput label="Beneficiary Address" type="text" placeholder="optional" />
      </Form.Item>
    </>
  );

  const modalForm = (
    <div>
      {modalForm1}
      {modalForm2}
    </div>
  );
  return (
    <>
      <Modal
        title={!isMobile && <h1 className={styles.payeemodal__Title}>Add payee</h1>}
        visible={isModalvisible}
        confirmLoading={isLoading}
        width={1000}
        onCancel={handleCancel}
        style={{ top: 20 }}
        closable={!isMobile}
        footer={[
          <CustomBtn key="submit" onClick={() => handleOk(form)} loading={isLoading}>
            Save Payee
          </CustomBtn>,
        ]}
      >
        <div className={styles.payeemodal__InstructionContainer}>
          {isMobile && (
            <div>
              <img src={leftArrow} alt="back-arrow-icon" onClick={handleCancel} />
              <span className={styles.payeemodal_mobileText}> Add Payee </span>
            </div>
          )}
          <p className={styles.payeemodal_mobilesubText}>Please enter the payee's information</p>
        </div>
        <Form form={form} scrollToFirstError name="addPayee">
          {isMobile ? (
            modalForm
          ) : (
            <Row className={styles.payeemodal__form}>
              <Col className={styles.payeemodal__Column}>{modalForm1}</Col>
              <Col className={styles.payeemodal__Column}>{modalForm2}</Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddPayeeModal;
