import { createStore, applyMiddleware, compose } from 'redux';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

import { INITIAL_STATE as INITIAL_AUTH_STATE } from './modules/auth';
import { INITIAL_STATE as INITIAL_HOME_STATE } from './modules/home';
import { INITIAL_STATE as INITIAL_TRANSACTION_STATE } from './modules/transaction';
import { INITIAL_STATE as INITIAL_BALANCE_STATE } from './modules/balance';
import { INITIAL_STATE as INITIAL_PAYEE_STATE } from './modules/payee';
import { INITIAL_STATE as INITIAL_RATE_STATE } from './modules/rate';
import { IApplicationState } from '../common/interface';

const sagaMiddleware = createSagaMiddleware();

// redux dev tool
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const initialState: IApplicationState = {
  auth: INITIAL_AUTH_STATE,
  home: INITIAL_HOME_STATE,
  transaction: INITIAL_TRANSACTION_STATE,
  balance: INITIAL_BALANCE_STATE,
  payee: INITIAL_PAYEE_STATE,
  rate: INITIAL_RATE_STATE,
};

export default createStore(rootReducer, initialState, enhancer);

sagaMiddleware.run(rootSaga);
