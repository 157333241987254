export const FETCH_PAYEE_INITIATED = 'FETCH_PAYEE_INITIATED';
export const FETCH_PAYEE_SUCCEEDED = 'FETCH_PAYEE_SUCCEEDED';
export const FETCH_PAYEE_FAILED = 'FETCH_PAYEE_FAILED';

export const DELETE_PAYEE_INITIATED = 'DELETE_PAYEE_INITIATED';
export const DELETE_PAYEE_SUCCEEDED = 'DELETE_PAYEE_SUCCEEDED';
export const DELETE_PAYEE_FAILED = 'DELETE_PAYEE_FAILED';

export const ADD_PAYEE_INITIATED = 'ADD_PAYEE_INITIATED';
export const ADD_PAYEE_SUCCESSFUL = 'ADD_PAYEE_SUCCESFUL';
export const ADD_PAYEE_FAILED = 'ADD_PAYEE_FAILED';

export const CLEAR_PAYEE_STATE = 'CLEAR_PAYEE_STATE';
