import OTP from 'src/components/Otp';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectOtp } from 'src/store/modules/auth';
import { clearAccountCreation } from 'src/store/modules/auth/actions';

interface IProps {
  onNext: () => void;
  onFormChange: (values: { title?: string; subTitle?: string; formDescription?: string }) => void;
}
const defaultProps = {
  onNext: () => {},
};

const Verification = ({ onFormChange, onNext }: IProps & typeof defaultProps) => {
  const { username } = useParams<{ username: string }>();
  const { isComplete } = useSelector(selectOtp);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isComplete) onNext();
  }, [isComplete, onNext]);

  const resetAcccountCreation = useCallback(() => {
    dispatch(clearAccountCreation());
  }, [dispatch]);

  useEffect(() => {
    return () => resetAcccountCreation();
  }, [resetAcccountCreation]);

  useEffect(() => {
    onFormChange({});
  }, [onFormChange]);

  return <OTP type="signup" username={username} />;
};

Verification.defaultProps = defaultProps;

export default Verification;
