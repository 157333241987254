import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import styles from './index.module.css';

interface IProps {
  disable?: boolean;
  classname?: string;
  iconSize?: number;
  onClick: () => void;
}

const defaultProps = {
  classname: '',
  disable: false,
  iconSize: 24,
  onClick: () => {},
};

const formBackArrow = ({ onClick, classname, disable, iconSize }: IProps & typeof defaultProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disable}
      type="ghost"
      className={`${styles.arrowBody} ${classname}`}
      icon={<LeftOutlined style={{ fontSize: iconSize }} />}
    />
  );
};

formBackArrow.defaultProps = defaultProps;

export default formBackArrow;
