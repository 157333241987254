const apiVersion = process.env.REACT_APP_API_VERSION || 'v6';
const appConfig = {
  DEV: {
    endpoint: `https://cegz8jalzd.execute-api.ap-east-1.amazonaws.com/latest/${apiVersion}/`,
    terms: 'https://fintnc.s3.ap-east-1.amazonaws.com/fintnc/index.html',
    awsConfig: {
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_RS59yPqCC',
      appClientId: 'd8g2llulu3sus1ro87t3vmhil',
      userPoolWebClientId: 'd8g2llulu3sus1ro87t3vmhil',
    },
  },
  PROD: {
    endpoint: `https://r5ybkahhu2.execute-api.ap-east-1.amazonaws.com/latest/${apiVersion}/`,
    terms: 'https://finproductionterms.s3-ap-northeast-1.amazonaws.com/Terms.html',
    awsConfig: {
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_vdGACfOnV',
      appClientId: '3lfd18m5m731ei1idlq27e91tb',
      userPoolWebClientId: '3lfd18m5m731ei1idlq27e91tb',
    },
  },
  GITSTART: {
    endpoint: `https://5pa7xlr46b.execute-api.ap-east-1.amazonaws.com/latest/${apiVersion}/`,
    terms: 'https://fintnc.s3.ap-east-1.amazonaws.com/fintnc/index.html',
    awsConfig: {
      region: 'ap-northeast-1',
      userPoolId: process.env.REACT_APP_USER_POOL || 'ap-northeast-1_wwQfRSYVG',
      appClientId: process.env.REACT_APP_CLIENT_ID || '1rs4rfk8lucu8n8lq9d9qja56v',
      userPoolWebClientId: process.env.REACT_APP_CLIENT_ID || '1rs4rfk8lucu8n8lq9d9qja56v',
    },
  },
};

export default appConfig;
