import { useCallback, useState } from 'react';
import { Form, notification } from 'antd';
import { DefaultFormInput } from 'src/components/CustomInputs';
import CustomBtn from 'src/components/Button';

import styles from './registration.module.css';
import { ReactPhoneInput } from 'src/components/CustomInputs/index';
import { IFormItem, inviteCode } from './form';

interface IRegForm {
  company_name?: string;
  email?: string;
  phone_number?: string;
  invite_code?: string;
  password?: string;
  confirmPassword?: string;
  lastUpdatedForm?: 'PASSWORD_FORM' | 'DETAILS_FORM';
}

interface IProps {
  formItem: IFormItem;
  loading: boolean;
  initialFormValues?: IRegForm;
  onFinish: (formValues: { [idex: string]: unknown }) => void;
}
interface IFormError {
  [index: string]: string[];
}
type TFormError = IFormError | null;
const defaultProps = {
  formItem: {},
  initialFormValues: {},
  loading: false,
};

const Welcome = ({
  formItem,
  onFinish,
  loading,
  initialFormValues,
}: IProps & typeof defaultProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [form] = Form.useForm();

  const handleFormChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object.keys(errors).length ? errors : null);
  }, [form]);

  const handleFinish = (values: { [index: string]: unknown }) => {
    if (values.invite_code !== inviteCode) {
      return notification.error({
        message: `validation Error: invalid invite code`,
        description: 'Kindly enter a valid code',
      });
    }

    onFinish({
      ...values,
      lastUpdatedForm: 'DETAILS_FORM',
    });
  };

  return (
    <Form
      onFinish={handleFinish}
      form={form}
      className={styles.form}
      onFieldsChange={handleFormChange}
    >
      <div>
        {(formItem.fields || []).map((item) => (
          <Form.Item
            key={`welcomeForm-${item.name}`}
            initialValue={initialFormValues[item?.name]}
            className={styles.formItem}
            name={item?.name}
            rules={item?.rules}
          >
            {item.name !== 'phone_number' ? (
              <DefaultFormInput
                error={formError ? !!formError[item.name] : false}
                label={item?.label}
                type={item.type}
                placeholder={item?.placeholder}
              />
            ) : (
              <ReactPhoneInput
                error={formError ? !!formError[item.name] : false}
                international
                country="US"
                defaultCountry="US"
                placeholder={item?.placeholder}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            )}
          </Form.Item>
        ))}
      </div>
      <div className={styles.formContainer2}>
        <CustomBtn
          loading={loading}
          disabled={!!formError}
          htmlType="submit"
          className={styles.formBtn}
          logo
        >
          Next
        </CustomBtn>
      </div>
    </Form>
  );
};

Welcome.defaultProps = defaultProps;

export default Welcome;
