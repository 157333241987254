import { memo } from 'react';
import { Input } from 'antd';
import styles from './Search.module.css';
const { Search: AntSearch } = Input;

const defaultProps = {
  children: '',
  className: '',
  ...AntSearch.defaultProps,
};

type searchProp = {
  onSearch: (value: string) => void;
};

const Search = ({ onSearch }: searchProp) => {
  return (
    <>
      <section>
        <div>
          <AntSearch
            placeholder="input search text"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            className={styles.search_btn}
            bordered={false}
          />
        </div>
      </section>
    </>
  );
};

Search.defaultProps = defaultProps;
export default memo(Search);
