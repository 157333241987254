const currencies = [
  {
    currency_name: 'Afghan Afghani',
    currency_code: 'AFN',
  },
  {
    currency_name: 'Albanian Lek',
    currency_code: 'ALL',
  },
  {
    currency_name: 'Algerian Dinar',
    currency_code: 'DZD',
  },
  {
    currency_name: 'US Dollar',
    currency_code: 'USD',
  },
  {
    currency_name: 'Euro',
    currency_code: 'EUR',
  },
  {
    currency_name: 'Angolan Kwanza',
    currency_code: 'AOA',
  },
  {
    currency_name: 'East Caribbean Dollar',
    currency_code: 'XCD',
  },
  {
    currency_name: 'Argentine Peso',
    currency_code: 'ARS',
  },
  {
    currency_name: 'Armenian Dram',
    currency_code: 'AMD',
  },
  {
    currency_name: 'Aruban Florin',
    currency_code: 'AWG',
  },
  {
    currency_name: 'Australian Dollar',
    currency_code: 'AUD',
  },
  {
    currency_name: 'Azerbaijanian Manat',
    currency_code: 'AZN',
  },
  {
    currency_name: 'Bahamian Dollar',
    currency_code: 'BSD',
  },
  {
    currency_name: 'Bahraini Dinar',
    currency_code: 'BHD',
  },
  {
    currency_name: 'Bangladeshi Taka',
    currency_code: 'BDT',
  },
  {
    currency_name: 'Barbados Dollar',
    currency_code: 'BBD',
  },
  {
    currency_name: 'Belarussian Ruble',
    currency_code: 'BYN',
  },
  {
    currency_name: 'Belize Dollar',
    currency_code: 'BZD',
  },
  {
    currency_name: 'West AFrican CFA Franc BCEAO',
    currency_code: 'XOF',
  },
  {
    currency_name: 'Bermudian Dollar',
    currency_code: 'BMD',
  },
  {
    currency_name: 'Bhutanese Ngultrum',
    currency_code: 'BTN',
  },
  {
    currency_name: 'Indian Rupee',
    currency_code: 'INR',
  },
  {
    currency_name: 'Bolivian Boliviano',
    currency_code: 'BOB',
  },
  {
    currency_name: 'Mvdol',
    currency_code: 'BOV',
  },
  {
    currency_name: 'Bosnia-Herzegovina Convertible Mark',
    currency_code: 'BAM',
  },
  {
    currency_name: 'Botswanan Pula',
    currency_code: 'BWP',
  },
  {
    currency_name: 'Norwegian Krone',
    currency_code: 'NOK',
  },
  {
    currency_name: 'Brazilian Real',
    currency_code: 'BRL',
  },
  {
    currency_name: 'Brunei Dollar',
    currency_code: 'BND',
  },
  {
    currency_name: 'Bulgarian Lev',
    currency_code: 'BGN',
  },
  {
    currency_name: 'Burundi Franc',
    currency_code: 'BIF',
  },
  {
    currency_name: 'Cabo Verde Escudo',
    currency_code: 'CVE',
  },
  {
    currency_name: 'Cambodian Riel',
    currency_code: 'KHR',
  },
  {
    currency_name: 'Central African CFA Franc BEAC',
    currency_code: 'XAF',
  },
  {
    currency_name: 'Canadian Dollar',
    currency_code: 'CAD',
  },
  {
    currency_name: 'Cayman Islands Dollar',
    currency_code: 'KYD',
  },
  {
    currency_name: 'Chilean Unit of Account',
    currency_code: 'CLF',
  },
  {
    currency_name: 'Chilean Peso',
    currency_code: 'CLP',
  },
  {
    currency_name: 'Chinese Yuan Renminbi',
    currency_code: 'CNY',
  },
  {
    currency_name: 'Colombian Peso',
    currency_code: 'COP',
  },
  {
    currency_name: 'Colombian Unidad de Valor Real',
    currency_code: 'COU',
  },
  {
    currency_name: 'Comoro Franc',
    currency_code: 'KMF',
  },
  {
    currency_name: 'Congolese Franc',
    currency_code: 'CDF',
  },
  {
    currency_name: 'New Zealand Dollar',
    currency_code: 'NZD',
  },
  {
    currency_name: 'Costa Rican Colon',
    currency_code: 'CRC',
  },
  {
    currency_name: 'Croation Kuna',
    currency_code: 'HRK',
  },
  {
    currency_name: 'Cuban Convertible Peso',
    currency_code: 'CUC',
  },
  {
    currency_name: 'Cuban Peso',
    currency_code: 'CUP',
  },
  {
    currency_name: 'Netherlands Antillean Guilder',
    currency_code: 'ANG',
  },
  {
    currency_name: 'Czech Koruna',
    currency_code: 'CZK',
  },
  {
    currency_name: 'Danish Krone',
    currency_code: 'DKK',
  },
  {
    currency_name: 'Djibouti Franc',
    currency_code: 'DJF',
  },
  {
    currency_name: 'Dominican Peso',
    currency_code: 'DOP',
  },
  {
    currency_name: 'Egyptian Pound',
    currency_code: 'EGP',
  },
  {
    currency_name: 'El Salvador Colon',
    currency_code: 'SVC',
  },
  {
    currency_name: 'Eritrean Nakfa',
    currency_code: 'ERN',
  },
  {
    currency_name: 'Ethiopian Birr',
    currency_code: 'ETB',
  },
  {
    currency_name: 'Falkland Islands Pound',
    currency_code: 'FKP',
  },
  {
    currency_name: 'Danish Krone',
    currency_code: 'DKK',
  },
  {
    currency_name: 'Fiji Dollar',
    currency_code: 'FJD',
  },
  {
    currency_name: 'Gambian Dalasi',
    currency_code: 'GMD',
  },
  {
    currency_name: 'Georgian Lari',
    currency_code: 'GEL',
  },
  {
    currency_name: 'Ghana Cedi',
    currency_code: 'GHS',
  },
  {
    currency_name: 'Gibraltar Pound',
    currency_code: 'GIP',
  },
  {
    currency_name: 'Guatemalan Quetzal',
    currency_code: 'GTQ',
  },
  {
    currency_name: 'Guinea Franc',
    currency_code: 'GNF',
  },
  {
    currency_name: 'Guyana Dollar',
    currency_code: 'GYD',
  },
  {
    currency_name: 'Haitian Gourde',
    currency_code: 'HTG',
  },
  {
    currency_name: 'Honduran Lempira',
    currency_code: 'HNL',
  },
  {
    currency_name: 'Hong Kong Dollar',
    currency_code: 'HKD',
  },
  {
    currency_name: 'Hungarian Forint',
    currency_code: 'HUF',
  },
  {
    currency_name: 'Iceland Krona',
    currency_code: 'ISK',
  },
  {
    currency_name: 'Indian Rupee',
    currency_code: 'INR',
  },
  {
    currency_name: 'Indonesian Rupiah',
    currency_code: 'IDR',
  },
  {
    currency_name: 'Special Drawing Right',
    currency_code: 'XDR',
  },
  {
    currency_name: 'Iranian Rial',
    currency_code: 'IRR',
  },
  {
    currency_name: 'Iraqi Dinar',
    currency_code: 'IQD',
  },
  {
    currency_name: 'Great British Pound Sterling',
    currency_code: 'GBP',
  },
  {
    currency_name: 'New Israeli Sheqel',
    currency_code: 'ILS',
  },
  {
    currency_name: 'Jamaican Dollar',
    currency_code: 'JMD',
  },
  {
    currency_name: 'Japanese Yen',
    currency_code: 'JPY',
  },
  {
    currency_name: 'Jordanian Dinar',
    currency_code: 'JOD',
  },
  {
    currency_name: 'Kazakhstani Tenge',
    currency_code: 'KZT',
  },
  {
    currency_name: 'Kenyan Shilling',
    currency_code: 'KES',
  },
  {
    currency_name: 'North Korean Won',
    currency_code: 'KPW',
  },
  {
    currency_name: 'South Korean Won',
    currency_code: 'KRW',
  },
  {
    currency_name: 'Kuwaiti Dinar',
    currency_code: 'KWD',
  },
  {
    currency_name: 'Kyrgystani Som',
    currency_code: 'KGS',
  },
  {
    currency_name: 'Laotian Kip',
    currency_code: 'LAK',
  },
  {
    currency_name: 'Lebanese Pound',
    currency_code: 'LBP',
  },
  {
    currency_name: 'Lesotho Loti',
    currency_code: 'LSL',
  },
  {
    currency_name: 'South African Rand',
    currency_code: 'ZAR',
  },
  {
    currency_name: 'Liberian Dollar',
    currency_code: 'LRD',
  },
  {
    currency_name: 'Libyan Dinar',
    currency_code: 'LYD',
  },
  {
    currency_name: 'Macanese Pataca',
    currency_code: 'MOP',
  },
  {
    currency_name: 'Malagasy Ariary',
    currency_code: 'MGA',
  },
  {
    currency_name: 'Malawian Kwacha',
    currency_code: 'MWK',
  },
  {
    currency_name: 'Malaysian Ringgit',
    currency_code: 'MYR',
  },
  {
    currency_name: 'Maldivian Rufiyaa',
    currency_code: 'MVR',
  },
  {
    currency_name: 'Mauritanian Ouguiya',
    currency_code: 'MRU',
  },
  {
    currency_name: 'Mauritius Rupee',
    currency_code: 'MUR',
  },
  {
    currency_name: 'ADB Unit of Account',
    currency_code: 'XUA',
  },
  {
    currency_name: 'Mexican Peso',
    currency_code: 'MXN',
  },
  {
    currency_name: 'Mexican Unidad de Inversion (UDI)',
    currency_code: 'MXV',
  },
  {
    currency_name: 'Moldovan Leu',
    currency_code: 'MDL',
  },
  {
    currency_name: 'Mongolian Tugrik',
    currency_code: 'MNT',
  },
  {
    currency_name: 'Moroccan Dirham',
    currency_code: 'MAD',
  },
  {
    currency_name: 'Mozambique Metical',
    currency_code: 'MZN',
  },
  {
    currency_name: 'Myanmar Kyat',
    currency_code: 'MMK',
  },
  {
    currency_name: 'Namibian Dollar',
    currency_code: 'NAD',
  },
  {
    currency_name: 'Nepalese Rupee',
    currency_code: 'NPR',
  },
  {
    currency_name: 'New Zealand Dollar',
    currency_code: 'NZD',
  },
  {
    currency_name: 'Nicaraguan Córdoba',
    currency_code: 'NIO',
  },
  {
    currency_name: 'Norwegian Krone',
    currency_code: 'NOK',
  },
  {
    currency_name: 'Omani Rial',
    currency_code: 'OMR',
  },
  {
    currency_name: 'Pakistani Rupee',
    currency_code: 'PKR',
  },
  {
    currency_name: 'Panamanian Balboa',
    currency_code: 'PAB',
  },
  {
    currency_name: 'Papua New Guinean Kina',
    currency_code: 'PGK',
  },
  {
    currency_name: 'Paraguayan Guarani',
    currency_code: 'PYG',
  },
  {
    currency_name: 'Peruvian Nuevo Sol',
    currency_code: 'PEN',
  },
  {
    currency_name: 'Philippine Peso',
    currency_code: 'PHP',
  },
  {
    currency_name: 'Poland Zloty',
    currency_code: 'PLN',
  },
  {
    currency_name: 'Qatari Rial',
    currency_code: 'QAR',
  },
  {
    currency_name: 'Macedonian Denar',
    currency_code: 'MKD',
  },
  {
    currency_name: 'Romanian Leu',
    currency_code: 'RON',
  },
  {
    currency_name: 'Russian Ruble',
    currency_code: 'RUB',
  },
  {
    currency_name: 'Rwandan Franc',
    currency_code: 'RWF',
  },
  {
    currency_name: 'Saint Helena Pound',
    currency_code: 'SHP',
  },
  {
    currency_name: 'Samoan Tala',
    currency_code: 'WST',
  },
  {
    currency_name: 'São Tomé and Príncipe Dobra',
    currency_code: 'STN',
  },
  {
    currency_name: 'Saudi Riyal',
    currency_code: 'SAR',
  },
  {
    currency_name: 'Serbian Dinar',
    currency_code: 'RSD',
  },
  {
    currency_name: 'Seychelles Rupee',
    currency_code: 'SCR',
  },
  {
    currency_name: 'Sierra Leonean Leone',
    currency_code: 'SLL',
  },
  {
    currency_name: 'Singapore Dollar',
    currency_code: 'SGD',
  },
  {
    currency_name: 'Sucre',
    currency_code: 'XSU',
  },
  {
    currency_name: 'Solomon Islands Dollar',
    currency_code: 'SBD',
  },
  {
    currency_name: 'Somali Shilling',
    currency_code: 'SOS',
  },
  {
    currency_name: 'South Sudanese Pound',
    currency_code: 'SSP',
  },
  {
    currency_name: 'Sri Lankan Rupee',
    currency_code: 'LKR',
  },
  {
    currency_name: 'Sudanese Pound',
    currency_code: 'SDG',
  },
  {
    currency_name: 'Surinamese Dollar',
    currency_code: 'SRD',
  },
  {
    currency_name: 'Swazi Lilangeni',
    currency_code: 'SZL',
  },
  {
    currency_name: 'Swedish Krona',
    currency_code: 'SEK',
  },
  {
    currency_name: 'WIR Euro',
    currency_code: 'CHE',
  },
  {
    currency_name: 'Swiss Franc',
    currency_code: 'CHF',
  },
  {
    currency_name: 'Syrian Pound',
    currency_code: 'SYP',
  },
  {
    currency_name: 'New Taiwan Dollar',
    currency_code: 'TWD',
  },
  {
    currency_name: 'Tajikistani Somoni',
    currency_code: 'TJS',
  },
  {
    currency_name: 'Tanzanian Shilling',
    currency_code: 'TZS',
  },
  {
    currency_name: 'Thai Baht',
    currency_code: 'THB',
  },
  {
    currency_name: 'Tongan Pa’anga',
    currency_code: 'TOP',
  },
  {
    currency_name: 'Trinidad and Tobago Dollar',
    currency_code: 'TTD',
  },
  {
    currency_name: 'Tunisian Dinar',
    currency_code: 'TND',
  },
  {
    currency_name: 'Turkish Lira',
    currency_code: 'TRY',
  },
  {
    currency_name: 'Turkmenistani New Manat',
    currency_code: 'TMT',
  },
  {
    currency_name: 'Ugandan Shilling',
    currency_code: 'UGX',
  },
  {
    currency_name: 'Ukrainian Hryvnia',
    currency_code: 'UAH',
  },
  {
    currency_name: 'UAE Dirham',
    currency_code: 'AED',
  },
  {
    currency_name: 'US Dollar (Next Day)',
    currency_code: 'USN',
  },
  {
    currency_name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
    currency_code: 'UYI',
  },
  {
    currency_name: 'Peso Uruguayo',
    currency_code: 'UYU',
  },
  {
    currency_name: 'Uzbekistan Sum',
    currency_code: 'UZS',
  },
  {
    currency_name: 'Vanuatu Vatu',
    currency_code: 'VUV',
  },
  {
    currency_name: 'Venezuelan Bolivar Fuerte',
    currency_code: 'VEF',
  },
  {
    currency_name: 'Vietnamese Dong',
    currency_code: 'VND',
  },
  {
    currency_name: 'Yemeni Rial',
    currency_code: 'YER',
  },
  {
    currency_name: 'Zambian Kwacha',
    currency_code: 'ZMW',
  },
  {
    currency_name: 'Zimbabwe Dollar',
    currency_code: 'ZWL',
  },
];

const getCurrencyName = (code: string): string => {
  const currency = currencies.find((cur) => cur.currency_code === code);

  if (currency) {
    return currency.currency_name;
  } else {
    return code;
  }
};

export default getCurrencyName;
