import { Auth } from 'aws-amplify';
import { User, IUserSignIn } from '../../../common/interface';
import { ICreateAccount } from './types.d';
import { ConfirmOtpRequest } from './types';

const signIn = async ({ username, password }: IUserSignIn) => {
  const user: User = await Auth.signIn(username, password);

  return user;
};

const getSignupOtp = async (username: string) => {
  await Auth.resendSignUp(username);
};

const signOut = async () => {
  await Auth.signOut();
};

const sendConfirmation = async (username: string) => {
  await Auth.forgotPassword(username);
};

const resetPassword = async ({
  code,
  password,
  username,
}: {
  code: string;
  password: string;
  username: string;
}) => {
  await Auth.forgotPasswordSubmit(username, code, password);
};

const signup = async (account: ICreateAccount) => {
  await Auth.signUp({
    username: account.email,
    password: account.password,
    attributes: {
      phone_number: account.phone_number,
      email: account.email,
      'custom:company_name': account.company_name,
    },
  });
};

const confirmOtp = async (req: ConfirmOtpRequest) => {
  const verifCode = req.otp;
  const username = req.username;

  await Auth.confirmSignUp(username, verifCode);
};

const actions = {
  signIn,
  getSignupOtp,
  signOut,
  confirmOtp,
  sendConfirmation,
  resetPassword,
  signup,
};

export default actions;
