import { useState } from 'react';
import TransactionDetailCard from './DetailCard';
import { Table, IData } from './Table';
import styles from './index.module.css';
import { transactions } from './mockData';

const Transaction = () => {
  const [transaction, setTransaction] = useState<{ id: string; data: IData[] } | null>(null);

  return (
    <section className={styles.transaction}>
      <Table
        data={transactions()}
        handleRowClick={(transactionId, detail) => {
          setTransaction({
            id: transactionId,
            data: detail,
          });
        }}
      />
      {transaction && (
        <TransactionDetailCard
          close={() => setTransaction(null)}
          transactionId={transaction.id}
          data={transaction.data}
        />
      )}
    </section>
  );
};

export default Transaction;
