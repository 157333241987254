import styles from './index.module.css';

interface IProps {
  errorState: 'valid' | 'invalid';
  text: string;
}

const defaultProps = {
  errorState: 'invalid',
  text: '',
};

const PasswordChecker = (props: IProps & typeof defaultProps) => {
  return (
    <div>
      <span className={`${styles.circle} ${styles[props.errorState]}`}></span>
      <span
        className={`${styles.text} ${props.errorState === 'invalid' ? `${styles.badText}` : ''}`}
      >
        {props.text}
      </span>
    </div>
  );
};
PasswordChecker.defaultProps = defaultProps;

export default PasswordChecker;
