import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import { User, IUserSignIn } from '../../../common/interface';
import {
  IFetchSigninInitiatedAction,
  IFetchSignInSucceededAction,
  RequestOtpInitiatedAction,
  RequestOtpSucceededAction,
  IFetchSignOutInitiatedAction,
  IFetchSignOutSucceededAction,
  IFetchSignOutFailedAction,
  OtpRequest,
  ConfirmOtpInitiatedAction,
  IFetchPasswordResetInitiatedAction,
  IFetchPasswordResetSucceededAction,
  IFetchPasswordResetFailedAction,
  IFetchPasswordResetConfirmationInitiatedAction,
  IFetchPasswordResetConfirmationSucceededAction,
  IFetchPasswordResetConfirmationFailedAction,
  IClearResetPasswordAction,
  IClearErrorAction,
  IFetchCreateAccountInitiatedAction,
  IFetchCreateAccountFailedAction,
  IFetchCreateAccountSucceededAction,
  ICreateAccount,
  RequestOtpFailedAction,
  OtpError,
  ConfirmOtpFailedAction,
  ConfirmOtpRequest,
  ConfirmOtpSucceded,
  IClearAccountCreationAction,
  IAcceptTermsAndConditions,
  IClearOtpAction,
} from './types';

export const fetchSignInInitiated: ActionCreator<IFetchSigninInitiatedAction> = (
  data: IUserSignIn,
) => ({
  type: TYPES.FETCH_SIGNIN_INITIATED,
  payload: data,
});

export const fetchSignInSucceeded: ActionCreator<IFetchSignInSucceededAction> = (data: User) => ({
  type: TYPES.FETCH_SIGNIN_SUCCEEDED,
  payload: data,
});

export const fetchSignInFailed: ActionCreator<IFetchSignOutFailedAction> = (data: unknown) => ({
  type: TYPES.FETCH_SIGNIN_FAILED,
  payload: data,
});

export const fetchSignOutInitiated: ActionCreator<IFetchSignOutInitiatedAction> = () => ({
  type: TYPES.FETCH_SIGNOUT_INITIATED,
  payload: null,
});

export const fetchSignOutSucceeded: ActionCreator<IFetchSignOutSucceededAction> = () => ({
  type: TYPES.FETCH_SIGNOUT_SUCCEEDED,
  payload: null,
});

export const fetchSignOutFailed: ActionCreator<IFetchSignOutFailedAction> = (data: unknown) => ({
  type: TYPES.FETCH_SIGNOUT_FAILED,
  payload: data,
});

export const requestOtpInitiated: ActionCreator<RequestOtpInitiatedAction> = (
  data: OtpRequest,
) => ({
  type: TYPES.REQUEST_OTP_INITIATED,
  payload: data,
});

export const requestOtpSucceded: ActionCreator<RequestOtpSucceededAction> = () => ({
  type: TYPES.REQUEST_OTP_SUCCEEDED,
  payload: null,
});
export const requestOtpFailed: ActionCreator<RequestOtpFailedAction> = (data: OtpError) => ({
  type: TYPES.REQUEST_OTP_FAILED,
  payload: data,
});

export const confirmOtpInitiated: ActionCreator<ConfirmOtpInitiatedAction> = (
  data: ConfirmOtpRequest,
) => ({
  type: TYPES.CONFIRM_OTP_INITIATED,
  payload: data,
});

export const confirmOtpSucceded: ActionCreator<ConfirmOtpSucceded> = () => ({
  type: TYPES.CONFIRM_OTP_SUCCEEDED,
  payload: null,
});
export const confirmOtpFailed: ActionCreator<ConfirmOtpFailedAction> = (data: OtpError) => ({
  type: TYPES.CONFIRM_OTP_FAILED,
  payload: data,
});

export const clearError: ActionCreator<IClearErrorAction> = () => ({
  type: TYPES.CLEAR_ERROR,
  payload: null,
});

export const fetchPasswordResetInitiated: ActionCreator<IFetchPasswordResetInitiatedAction> = (
  data: string,
) => ({
  type: TYPES.FETCH_PASSWORD_RESET_INITIATED,
  payload: data,
});

export const fetchPasswordResetSucceeded: ActionCreator<IFetchPasswordResetSucceededAction> = (
  data: string,
) => ({
  type: TYPES.FETCH_PASSWORD_RESET_SUCCEEDED,
  payload: data,
});

export const fetchPasswordResetFailed: ActionCreator<IFetchPasswordResetFailedAction> = (
  data: unknown,
) => ({
  type: TYPES.FETCH_PASSWORD_RESET_FAILED,
  payload: data,
});

export const fetchPasswordResetConfirmationInitiated: ActionCreator<IFetchPasswordResetConfirmationInitiatedAction> =
  (data: { code: string; password: string; username: string }) => ({
    type: TYPES.FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED,
    payload: data,
  });

export const fetchPasswordResetConfirmationSucceeded: ActionCreator<IFetchPasswordResetConfirmationSucceededAction> =
  () => ({
    type: TYPES.FETCH_PASSWORD_RESET_CONFIRMATION_SUCCEEDED,
    payload: null,
  });

export const fetchPasswordResetConfirmationFailed: ActionCreator<IFetchPasswordResetConfirmationFailedAction> =
  (data: unknown) => ({
    type: TYPES.FETCH_PASSWORD_RESET_CONFIRMATION_FAILED,
    payload: data,
  });

export const clearResetPassword: ActionCreator<IClearResetPasswordAction> = () => ({
  type: TYPES.CLEAR_RESET_PASSWORD,
  payload: null,
});

export const fetchCreateAccountInitiated: ActionCreator<IFetchCreateAccountInitiatedAction> = (
  data: ICreateAccount,
) => ({
  type: TYPES.FETCH_CREATE_ACCOUNT_INITIATED,
  payload: data,
});

export const fetchCreateAccountSucceeded: ActionCreator<IFetchCreateAccountSucceededAction> =
  (data: { email: string }) => ({
    type: TYPES.FETCH_CREATE_ACCOUNT_SUCCEEDED,
    payload: data,
  });

export const fetchCreateAccountFailed: ActionCreator<IFetchCreateAccountFailedAction> = (
  data: unknown,
) => ({
  type: TYPES.FETCH_CREATE_ACCOUNT_FAILED,
  payload: data,
});
export const acceptTermsAndConditions: ActionCreator<IAcceptTermsAndConditions> = () => ({
  type: TYPES.ACCEPT_TERMS_CONDITIONS,
  payload: null,
});

export const clearAccountCreation: ActionCreator<IClearAccountCreationAction> = () => ({
  type: TYPES.CLEAR_ACCOUNT_CREATION,
  payload: null,
});

export const clearOtp: ActionCreator<IClearOtpAction> = () => ({
  type: TYPES.CLEAR_OTP,
  payload: null,
});
