import { Layout, PageHeader } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import styles from './index.module.css';

interface IProps {
  Sider: React.ReactNode;
  Content: React.ReactNode;
  collapse: boolean;
  setCollapse: Function;
}

const MobileLayoutStructure = (props: IProps) => {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        {props.Sider}
        <Layout>
          <PageHeader className={styles.top_bar_header__mobile}>
            <ul className={styles.menu_horizontal}>
              <li>
                <LeftOutlined
                  className={styles.collapse__trigger}
                  rotate={props.collapse ? 180 : 0}
                  onClick={() => props.setCollapse(!props.collapse)}
                />
              </li>
              <li>
                <h1>ReadyFX</h1>
              </li>
            </ul>
          </PageHeader>
          {props.Content}
        </Layout>
      </Layout>
    </>
  );
};

export default MobileLayoutStructure;
