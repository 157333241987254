import style from './theia_auth.module.css';
import { useLocation } from 'react-router-dom';
import { TheiaSignUp } from '../component/modules/signup';
import { Login } from '../component/modules/login';
import ImageCarousel from 'src/components/ImageCarousel';
import './index.css';

const data = [
  {
    heading: 'Theia',
    title: 'ManageCards',
    subTitle:
      'Create both virtual or physical,block in a second, set limitations,well hello mr lincon virtual or physical,block in a second, set limitations,well hello mr lincon',
    image:
      'https://images.unsplash.com/photo-1546118653-7c0532915543?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80',
  },
  {
    heading: 'Theia',
    title: 'ManageCards',
    subTitle:
      'Create both virtual or physical,block in a second, set limitations,well hello mr lincon virtual or physical,block in a second, set limitations,well hello mr lincon',
    image:
      'https://images.unsplash.com/photo-1571715268998-d6e79bed5fc9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80',
  },
  {
    heading: '',
    title: '',
    subTitle: '',
    image:
      'https://images.unsplash.com/photo-1585909695677-2b0492f96e3b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80',
  },
];

const TheiaAuth = () => {
  const location = useLocation();

  return (
    <section data-testid="signup" className={style.signup}>
      <ImageCarousel carouselItem={data} className="img-carousel-container" />
      <aside className={style.controls}>
        <TheiaSignUp isVisible={location.pathname.includes('theia-signup')} />
        <Login isVisible={location.pathname.includes('theia-login')} />
      </aside>
    </section>
  );
};

export default TheiaAuth;
