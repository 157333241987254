import { useState, useCallback, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Form } from 'antd';

import AuthModal from '../AuthModal';
import { DefaultFormInput } from 'src/components/CustomInputs';
import { formItem, TFormError, IValidations } from './types';
import CustomBtn from 'src/components/Button';
import AuthForm from '../AuthFormWrapper';
import PasswordStrength from '../PasswordStrength';

import styles from './forgetpassword.module.css';

interface IProps {
  isLoading?: boolean;
  isVisible: boolean;
  onFinish?: (value?: unknown) => void;
  formItems?: formItem;
  onBackArrowClick?: () => void;
}
const defaultProps = {
  isVisible: false,
  isLoading: false,
  onFinish: () => {},
  onBackArrowClick: () => {},
  formItems: {},
};

const ForgetPasswordModal = ({
  isVisible,
  onFinish,
  isLoading,
  formItems,
  onBackArrowClick,
}: IProps & typeof defaultProps) => {
  const [form] = Form.useForm();
  const [formError, setFormErrors] = useState<TFormError>(null);
  const [password, setPassword] = useState<string>('');
  const [validations, setValidations] = useState<IValidations[]>([]);

  const handleFormChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object.keys(errors).length ? errors : null);
    setPassword(form.getFieldValue('password'));
  }, [form]);

  useEffect(() => {
    if (formItems.action === 'RESET_PASSSWORD') {
      let availableValidations: IValidations[] = [];

      formItems.fields[1].rules.forEach(({ validator, name = '' }) => {
        if (validator) availableValidations.push({ validator, name });
      });

      setValidations(availableValidations);
    }
  }, [formItems, formItems.fields]);

  return (
    <AuthModal isVisible={isVisible}>
      <AuthForm
        title={formItems?.title}
        subTitle={formItems?.subTitle}
        onBackarrowClick={onBackArrowClick}
        formDescription={formItems?.formDescription}
        showHeader
      >
        <Form
          className="forgotPasswordModal"
          form={form}
          onFinish={onFinish}
          name="forgetpassword_form"
          layout={'vertical'}
          requiredMark={false}
          onFieldsChange={handleFormChange}
        >
          {(formItems?.fields || []).map((item) => (
            <Form.Item
              key={`forgotPasswordModal-${item.name}`}
              className={styles.formItem}
              name={item?.name}
              {...(item.dependencies ? { dependencies: item.dependencies } : {})}
              rules={item?.rules}
            >
              <DefaultFormInput
                error={formError ? !!formError[item.name] : false}
                label={item?.label}
                type={item?.type}
                placeholder={item?.placeholder}
              />
            </Form.Item>
          ))}
          {formItems.action === 'RESET_PASSSWORD' && (
            <PasswordStrength password={password} rules={validations} />
          )}
          <div className={styles.btnContainer}>
            <Form.Item style={{ marginBottom: 0 }} className={styles.btnFormItem}>
              <CustomBtn
                disabled={isLoading || !!formError}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className={styles.btn}
              >
                {formItems?.button?.text}
              </CustomBtn>
            </Form.Item>
          </div>
        </Form>
      </AuthForm>
    </AuthModal>
  );
};

ForgetPasswordModal.defaultProps = defaultProps;

export default ForgetPasswordModal;
