import { useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import styles from './index.module.css';
import './index.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBalance } from 'src/store/modules/balance';
import { fetchBalancesInitiated } from 'src/store/modules/balance/actions';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps {
  collapse?: boolean;
  handleSignOut: Function;
  setCollapse: Function;
  handleMenuClick: Function;
  setBroken?: Function;
  Title?: React.ReactNode;
}

const FXSider = (props: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userBalance = useSelector(selectBalance);
  const balances = userBalance?.balances;
  balances?.forEach((element) => {
    element.name = element.currency;
    element.path = `/balances/${element.currency}`;
  });
  useEffect(() => {
    dispatch(fetchBalancesInitiated());
  }, [dispatch]);

  const mainMenuItems = [
    {
      name: 'FX Insights',
      path: '/',
      subTabs: null,
    },
    {
      name: 'Balances',
      path: '/balances',
      // subTabs: [...balances],
      subTabs: [
        {
          name: 'JPY',
          path: '/balances/JPY',
        },
      ],
    },
    {
      name: 'Transaction',
      path: '/transaction',
      subTabs: null,
    },
    {
      name: 'Payees',
      path: '/payees',
      subTabs: null,
    },
    {
      name: 'Profile',
      path: '/profile',
      subTabs: null,
    },
    {
      name: 'Notification',
      path: '/notification',
      subTabs: null,
    },
  ];

  const { collapse, setCollapse, handleMenuClick, handleSignOut, setBroken, Title } = props;

  return (
    <Sider
      theme="light"
      className={`${styles.left_sider} left_sider__box_shadow`}
      width="256px"
      breakpoint={'md'}
      collapsedWidth={0}
      trigger={null}
      collapsed={collapse}
      onCollapse={(collapsed) => {
        setCollapse(collapsed);
      }}
      onBreakpoint={(broken) => setBroken && setBroken(broken)}
    >
      {Title}
      <Menu
        theme="light"
        className={styles.top_menu}
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
      >
        {mainMenuItems.map(({ name, path, subTabs }) =>
          subTabs ? (
            <SubMenu onTitleClick={() => handleMenuClick(path)} key={`${path}`} title={name}>
              {subTabs.map(({ name, path }) => (
                <Menu.Item key={`${path}`} onClick={() => handleMenuClick(path)}>
                  {name}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={`${path}`} onClick={() => handleMenuClick(path)}>
              {name}
            </Menu.Item>
          ),
        )}
      </Menu>
      <Menu theme="light" className={!collapse ? styles.bottom_menu : ''} mode="inline">
        <Menu.Item onClick={() => handleSignOut()} key="Sign Out">
          Sign Out
        </Menu.Item>
        <Menu.Item key="FAQ" onClick={() => handleMenuClick('/faq')}>
          FAQ
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default FXSider;
