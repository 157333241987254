import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Space } from 'antd';

import SetPassword from './SetPassword';
import TermsAndConditions from './TermsAndConditions';
import Registration from './Registration';

interface IProps {
  isVisible: boolean;
}

const SIGNUP = '/theia-signup';
const SET_PASSWORD = '/theia-signup/theia-setpassword';
const VERIFY = '/theia-signup/theia-verify';

const getIndexFromPath = (pathname: string) => {
  if (pathname === SET_PASSWORD) {
    return 1;
  } else if (pathname === VERIFY) {
    return 2;
  } else return 0;
};

const steps = [
  {
    title: 'Sign up',
    content: Registration,
    wrapperClass: '',
    url: SIGNUP,
    index: 0,
  },
  {
    title: 'Set Password',
    content: SetPassword,
    wrapperClass: '',
    url: SET_PASSWORD,
    index: 2,
  },
  {
    title: 'Terms & conditions',
    content: TermsAndConditions,
    wrapperClass: '',
    url: VERIFY,
    index: 1,
  },
];

const TheiaSignUp = (props: IProps) => {
  const history = useHistory();
  const path = history.location.pathname;
  const [current, setCurrent] = useState(getIndexFromPath(path));
  const next = useCallback(() => {
    if (current >= 2) {
      return history.push('/theia-login');
    }
    const url = steps[current + 1].url;
    history.push(url);
  }, [current, history]);

  const back = useCallback(() => {
    if (current <= 0) {
      return history.push('/theia-login');
    }
    const url = steps[current - 1].url;
    history.push(url);
  }, [current, history]);

  useEffect(() => {
    const pathIndex = getIndexFromPath(path);
    setCurrent(pathIndex);
  }, [history.location.pathname, path]);

  const CurrentComponent = steps[current].content;

  return (
    <div>
      {props.isVisible ? (
        <Space align="center" direction="vertical">
          <CurrentComponent onNext={next} onBack={back} />
        </Space>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TheiaSignUp;
