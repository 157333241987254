import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import LoginModal from './LoginModal';
import { fetchSignInInitiated, clearError } from 'src/store/modules/auth/actions';
import { selectAuth } from 'src/store/modules/auth';
import { getAuthError, TAuthError } from 'src/utils/Auth';

import { IUserSignIn } from 'src/common/interface';
import { useAuthentication } from '../../../../hooks/useAuth';

interface IProps {
  isVisible: boolean;
}
const defaultProps = {
  isVisible: false,
};
message.config({
  maxCount: 1,
});

const Modal = (props: IProps & typeof defaultProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector(selectAuth);
  const { user } = useAuthentication();

  const login = useCallback(
    (cred?: IUserSignIn) => {
      dispatch(fetchSignInInitiated(cred));
    },
    [dispatch],
  );
  const resetError = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  const pickAuthError = useCallback(getAuthError, []);

  useEffect(() => {
    if (error) {
      const authError = pickAuthError(error as TAuthError);
      message.error(authError);
    }

    return () => resetError();
  }, [error, pickAuthError, resetError]);

  useEffect(() => {
    if (user) {
      message.success(`Welcome! ${user['custom:company_name']}`);
    }
  }, [user]);

  return (
    <LoginModal
      onSignup={() => history.push('/signup')}
      onPasswordReset={() => history.push('/reset_password')}
      {...props}
      isLoading={loading}
      onLogin={login}
    />
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
