import { useLocation } from 'react-router-dom';

import ForgetPasswordModal from '../components/ForgetPasswordModal';
import Modal from '../components/LoginModal';
import Footer from 'src/components/Footer';
import styles from './auth.module.css';
import SignupModal from '../components/SignupModal/SignupModal';

const Auth = () => {
  const location = useLocation();

  return (
    <div className={`${styles.bg}`}>
      <Modal isVisible={location.pathname === '/login'} />
      <ForgetPasswordModal isVisible={location.pathname.includes('reset_password')} />
      <SignupModal isVisible={location.pathname.includes('signup')} />
      <Footer className={styles.auth__footer} />
    </div>
  );
};

export default Auth;
