import React from 'react';
import { Steps } from 'antd';

import styles from './index.module.css';

interface IProps {
  steps: { title: string }[];
  current?: number;
}

const Stepper = ({ steps, current }: IProps) => {
  const { Step } = Steps;

  return (
    <Steps className={styles.stepper} size="small" labelPlacement="vertical" current={current}>
      {steps.map((item) => (
        <Step key={item.title} title={item.title} />
      ))}
    </Steps>
  );
};

export default Stepper;
