import { useEffect, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { ReactComponent as ChevronDown } from '../../assets/svg/chevron-down.svg';
import paginatorStyles from './Paginator.module.css';

interface PaginatorProps {
  setPage: (page: number) => void;
  page: number;
  pages: number;
  itemsPerPage: number;
  items: any[] | null | undefined;
}

const defaultProps = {
  setPage: () => {},
  page: 1,
  pages: 1,
  itemsPerPage: 30,
  items: [],
};

const Paginator = ({ setPage, page, pages, itemsPerPage, items }: PaginatorProps) => {
  const [pageList, setPageList] = useState([1]);

  useEffect(() => {
    const list = [];
    for (let i = 1; i <= pages; i++) {
      list.push(i);
    }
    setPageList(list);
  }, [pages]);

  const paginatorMenu = (
    <Menu onClick={({ key }) => setPage(Number(key))}>
      {pageList.map((page) => (
        <Menu.Item key={page}>
          Page {page} of {pages}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={paginatorStyles.paginator}>
      <Dropdown trigger={['click', 'hover']} overlay={paginatorMenu}>
        <div className={paginatorStyles.dropdown}>
          <p>
            Page {page} of {pages}
          </p>
          <button>
            <ChevronDown />
          </button>
        </div>
      </Dropdown>
      <div className={paginatorStyles.indicator}>
        <p>
          {itemsPerPage * (page - 1) + 1}-
          {Math.min(itemsPerPage * (page - 1) + itemsPerPage, items?.length || 0)} out of{' '}
          {items?.length || 0}
        </p>
      </div>
    </div>
  );
};

Paginator.defaultProps = defaultProps;

export default Paginator;
