import { User } from '../../../common/interface';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSignInSucceeded,
  fetchSignInFailed,
  fetchSignOutSucceeded,
  fetchSignOutFailed,
  requestOtpSucceded,
  confirmOtpSucceded,
  requestOtpFailed,
  confirmOtpFailed,
  fetchPasswordResetSucceeded,
  fetchPasswordResetFailed,
  fetchPasswordResetConfirmationSucceeded,
  fetchPasswordResetConfirmationFailed,
  fetchCreateAccountFailed,
  fetchCreateAccountSucceeded,
} from './actions';
import {
  FETCH_SIGNIN_INITIATED,
  FETCH_SIGNIN_SUCCEEDED,
  FETCH_SIGNIN_FAILED,
  FETCH_SIGNOUT_INITIATED,
  FETCH_SIGNOUT_SUCCEEDED,
  FETCH_SIGNOUT_FAILED,
  REQUEST_OTP_INITIATED,
  REQUEST_OTP_SUCCEEDED,
  REQUEST_OTP_FAILED,
  CONFIRM_OTP_INITIATED,
  CONFIRM_OTP_SUCCEEDED,
  CONFIRM_OTP_FAILED,
  FETCH_PASSWORD_RESET_INITIATED,
  FETCH_PASSWORD_RESET_FAILED,
  FETCH_PASSWORD_RESET_SUCCEEDED,
  FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED,
  FETCH_PASSWORD_RESET_CONFIRMATION_FAILED,
  FETCH_PASSWORD_RESET_CONFIRMATION_SUCCEEDED,
  CLEAR_RESET_PASSWORD,
  CLEAR_ACCOUNT_CREATION,
  CLEAR_ERROR,
  FETCH_CREATE_ACCOUNT_INITIATED,
  FETCH_CREATE_ACCOUNT_FAILED,
  FETCH_CREATE_ACCOUNT_SUCCEEDED,
  ACCEPT_TERMS_CONDITIONS,
  CLEAR_OTP,
} from './actionTypes';

import API from './requests';
import { IApplicationState } from '../../../common/interface';
import {
  AuthActions,
  ConfirmOtpInitiatedAction,
  IAuthState,
  IFetchSigninInitiatedAction,
  IFetchPasswordResetInitiatedAction,
  IFetchPasswordResetConfirmationInitiatedAction,
  IFetchCreateAccountInitiatedAction,
  RequestOtpInitiatedAction,
  IFetchSignOutInitiatedAction,
} from './types';

export const INITIAL_STATE: IAuthState = {
  user: null,
  resetPassword: {
    error: null,
    isComplete: false,
    username: '',
    loading: false,
  },
  signup: {
    error: null,
    isComplete: false,
    email: '',
    terms: false,
    loading: false,
  },
  loading: false,
  error: null,
  otpRes: {
    message: '',
    loading: false,
    error: null,
    isSent: false,
    isComplete: false,
  },
};

export function* signInRequest(action: IFetchSigninInitiatedAction) {
  try {
    const user: User | undefined = yield call(API.signIn, action.payload);

    yield put(fetchSignInSucceeded(user || null));
  } catch (e) {
    yield put(fetchSignInFailed(e));
  }
}
function* requestOtp(action: RequestOtpInitiatedAction) {
  try {
    yield call(API.getSignupOtp, action.payload.username);

    yield put(requestOtpSucceded());
  } catch (e) {
    yield put(requestOtpFailed(e));
  }
}

function* confirmOtp(action: ConfirmOtpInitiatedAction) {
  try {
    yield call(API.confirmOtp, action.payload);

    yield put(confirmOtpSucceded());
  } catch (e) {
    yield put(confirmOtpFailed(e));
  }
}

export function* signOutRequest(action: IFetchSignOutInitiatedAction) {
  try {
    yield call(API.signOut);

    yield put(fetchSignOutSucceeded());
  } catch (e) {
    yield put(fetchSignOutFailed(e));
  }
}
export function* passwordResetConfirmationRequest(
  action: IFetchPasswordResetConfirmationInitiatedAction,
) {
  try {
    yield call(API.resetPassword, action.payload);

    yield put(fetchPasswordResetConfirmationSucceeded());
  } catch (e) {
    yield put(fetchPasswordResetConfirmationFailed(e));
  }
}
export function* accountCreationRequest(action: IFetchCreateAccountInitiatedAction) {
  try {
    yield call(API.signup, action.payload);

    yield put(fetchCreateAccountSucceeded({ email: action.payload.email }));
  } catch (e) {
    yield put(fetchCreateAccountFailed(e));
  }
}

export function* passwordResetRequest(action: IFetchPasswordResetInitiatedAction) {
  try {
    yield call(API.sendConfirmation, action.payload);

    yield put(fetchPasswordResetSucceeded(action.payload));
  } catch (e) {
    yield put(fetchPasswordResetFailed(e));
  }
}

export function* GetAuth() {
  yield takeLatest(FETCH_SIGNIN_INITIATED, signInRequest);
  yield takeLatest(FETCH_SIGNOUT_INITIATED, signOutRequest);
  yield takeLatest(FETCH_PASSWORD_RESET_INITIATED, passwordResetRequest);
  yield takeLatest(FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED, passwordResetConfirmationRequest);
  yield takeLatest(FETCH_CREATE_ACCOUNT_INITIATED, accountCreationRequest);
}
export function* GetOtp() {
  yield takeLatest(REQUEST_OTP_INITIATED, requestOtp);
}
export function* ConfirmOtp() {
  yield takeLatest(CONFIRM_OTP_INITIATED, confirmOtp);
}

export const selectAuth = (state: IApplicationState) => state.auth;
export const selectResetPassword = (state: IApplicationState) => state.auth.resetPassword;
export const selectSignup = (state: IApplicationState) => state.auth.signup;
export const selectOtp = (state: IApplicationState) => state.auth.otpRes;

export default function reducer(state = INITIAL_STATE, action: AuthActions) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_SIGNIN_SUCCEEDED:
      return { ...state, user: payload as User, loading: false };
    case FETCH_SIGNIN_INITIATED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SIGNIN_FAILED:
      return {
        ...state,
        error: payload as Error,
        loading: false,
      };
    case FETCH_SIGNOUT_INITIATED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SIGNOUT_SUCCEEDED:
      return {
        ...state,
        user: null,
        loading: false,
      };
    case FETCH_SIGNOUT_FAILED:
      return {
        ...state,
        error: payload as Error,
        loading: false,
      };
    case FETCH_PASSWORD_RESET_INITIATED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };
    case FETCH_PASSWORD_RESET_SUCCEEDED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          username: payload as string,
          loading: false,
          error: null,
        },
      };
    case FETCH_PASSWORD_RESET_FAILED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          error: payload as Error,
        },
      };
    case FETCH_PASSWORD_RESET_CONFIRMATION_INITIATED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };
    case FETCH_PASSWORD_RESET_CONFIRMATION_SUCCEEDED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isComplete: true,
          loading: false,
          error: null,
        },
      };
    case FETCH_PASSWORD_RESET_CONFIRMATION_FAILED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          isComplete: false,
          error: payload as Error,
        },
      };
    case FETCH_CREATE_ACCOUNT_INITIATED:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true,
        },
      };
    case FETCH_CREATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        signup: {
          ...state.signup,
          ...(payload as { email: string }),
          isComplete: true,
          loading: false,
          error: null,
        },
      };
    case FETCH_CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          error: payload as Error,
        },
      };
    case ACCEPT_TERMS_CONDITIONS:
      return {
        ...state,
        signup: {
          ...state.signup,
          terms: true,
        },
      };
    case CLEAR_ACCOUNT_CREATION:
      return {
        ...state,
        signup: {
          ...INITIAL_STATE.signup,
        },
      };
    case REQUEST_OTP_INITIATED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          loading: true,
        },
      };
    case REQUEST_OTP_SUCCEEDED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          loading: false,
          error: null,
          isSent: true,
        },
      };
    case REQUEST_OTP_FAILED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          loading: false,
          isSent: false,
          error: payload as Error,
        },
      };
    case CONFIRM_OTP_INITIATED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          loading: true,
        },
      };
    case CONFIRM_OTP_SUCCEEDED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          loading: false,
          error: null,
          isComplete: true,
        },
      };
    case CONFIRM_OTP_FAILED:
      return {
        ...state,
        otpRes: {
          ...state.otpRes,
          error: payload as Error,
          loading: false,
        },
      };
    case CLEAR_OTP:
      return {
        ...state,
        otpRes: {
          ...INITIAL_STATE.otpRes,
        },
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          ...INITIAL_STATE.resetPassword,
        },
      };
    default:
      return state;
  }
}
