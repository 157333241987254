import { Button } from 'antd';
import { ReactComponent as BackIcon } from 'src/assets/svg/backArrow.svg';
import styles from './index.module.css';

interface IProps {
  disable?: boolean;
  classname?: string;
  onClick: () => void;
}

const defaultProps = {
  classname: '',
  disable: false,
  onClick: () => {},
};

const BackArrow = ({ onClick, classname, disable }: IProps & typeof defaultProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disable}
      type="ghost"
      className={`${styles.arrowBody} ${classname}`}
      icon={<BackIcon />}
    />
  );
};

BackArrow.defaultProps = defaultProps;

export default BackArrow;
