import { ActionCreator } from 'redux';
import * as TYPES from './actionTypes';
import {
  IFetchQuotesInitiatedAction,
  IFetchQuotesSucceededAction,
  IFetchQuotesFailedAction,
  IQuote,
} from './types';

export const fetchQuotesInitiated: ActionCreator<IFetchQuotesInitiatedAction> = () => ({
  type: TYPES.FETCH_QUOTES_INITIATED,
});

export const fetchQuotesSucceeded: ActionCreator<IFetchQuotesSucceededAction> = (
  quotes: IQuote[],
) => ({
  type: TYPES.FETCH_QUOTES_SUCCEEDED,
  quotes,
});

export const fetchQuotesFailed: ActionCreator<IFetchQuotesFailedAction> = () => ({
  type: TYPES.FETCH_QUOTES_FAILED,
});
