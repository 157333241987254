import { Collapse } from 'antd';
import { ReactNode, Fragment } from 'react';
import { useWidth } from 'src/hooks/useWidth';
import styles from './faq.module.css';

const { Panel } = Collapse;

interface AccordionValues {
  header: string;
  value: ReactNode;
}

interface AccordionData {
  title: string;
  icon: any;
  accordionValues: AccordionValues[];
}
type AccordionProps = {
  items: AccordionData[];
};

interface AccordionItemsProps {
  accordionValues: AccordionValues[];
}

const AccordionItems = ({ accordionValues }: AccordionItemsProps) => {
  return (
    <Collapse ghost bordered={false} expandIconPosition={'right'}>
      {accordionValues.map((accValue, key) => (
        <Panel header={accValue.header} key={key} className={styles.accordion__header}>
          <p className={styles.accordion__text}>{accValue.value}</p>
        </Panel>
      ))}
    </Collapse>
  );
};

export const Accordion = ({ items }: AccordionProps) => {
  const { width } = useWidth();

  return (
    <Collapse ghost bordered={false} expandIconPosition={'right'}>
      {items.map((item, idx) => (
        <Fragment key={idx}>
          {width >= 768 && (
            <Fragment>
              <div className={styles.accordion__title}>
                <h1>{item.title}</h1>
              </div>
              <AccordionItems accordionValues={item.accordionValues} />
            </Fragment>
          )}
          {width < 768 && (
            <Panel
              className={styles.header_bordered}
              key={item.title}
              header={
                <div className={styles.accordion__title}>
                  <h1 className={styles.minimal__margin}>
                    <span className={styles.accordion__icon}>{item.icon}</span>
                    {item.title}
                  </h1>
                </div>
              }
            >
              <AccordionItems accordionValues={item.accordionValues} />
            </Panel>
          )}
        </Fragment>
      ))}
    </Collapse>
  );
};
