import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { endpoint as baseURL } from '../aws-exports';

/**
 * Axios basic configuration
 */

const request = axios.create({
  baseURL,
});

request.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const res = await Auth.currentSession();
  const jwt = res.getIdToken().getJwtToken();

  return {
    ...config,
    headers: { Authorization: jwt, 'Content-Type': 'application/json' },
  };
});

export default request;
